import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setExperienceLevel } from '../../../../../store/actions/jobListingActions'

const ExperienceLevel = () => {
  const dispatch = useDispatch()
  const { experienceLevel } = useSelector((state) => state.jobListing)  

  const options = [
    "Entry-level",
    "Mid-level",
    "Senior-level",
    "Internship"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="experienceLevel"
            title="Experience Level"
            value={experienceLevel}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setExperienceLevel(selectedValue === "Choose Option" ? null : selectedValue));
            }}            
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default ExperienceLevel

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`