import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import MainModal from '../../../../components/dashboard/modal/MainModal'
import Tags from '../../../../components/Tags'
import Flex from '../../../../components/layouts/Flex'

import { P } from '../../../../style/Typography.style'

import { setModal } from '../../../../store/actions/listsActions'
import { updateTalents } from '../../../../store/actions/talentActions'

import useFetch from '../../../../hooks/useFetch'

import axiosInstance from '../../../../services/config/AxiosIntance'

const ReactivateApplicant = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { talent, talents } = useSelector((state) => state.talents)
  const [loading, setLoading] = useState(false)

  const { data, error } = useFetch(`/applicant/${talent.id}/rejected`)

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])

  const handleReactivateApplicant = async () => {
    setLoading(true)
    try {
      await axiosInstance.put(`/applicant/${talent.id}/reactivate`)

      const filteredTalents = talents.filter(
        (applicant) => applicant.id !== talent.id
      )
      const selectedTalent = talent
      selectedTalent.applicant_status = 'UNLISTED'

      dispatch(updateTalents([selectedTalent, ...filteredTalents]))

      addToast(
        `${talent.name} is reactivated and declined tag has been removed.`,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      )
      setLoading(false)
      dispatch(setModal(''))
    } catch (error) {
      setLoading(false)
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <MainModal
      info
      title="Reactivate Applicant"
      buttonText="Reactivate Applicant"
      onClick={handleReactivateApplicant}
      loading={loading}
    >
      <P padding="0 0 0.5rem 0">
        {talent?.name} has been rejected on{' '}
        {moment(data?.resp?.rejectedDate).format('DD/MM/YYYY')} by{' '}
        {data?.resp?.rejectedBy}. Reactivating will enable this applicant to be
        processed by recruiters as a potential hire.{' '}
      </P>
      <P padding="0 0 0.5rem 0" color="#DC2929" weight="500">
        REASON(S) FOR REJECTION:
      </P>
      {data?.resp?.interviewRejectionReasons?.length > 0 ? (
        <Flex gap="0.25rem" direction="column">
          {data?.resp?.interviewRejectionReasons.map((e) => (
            <Tags
              key={e.rejection_reason_id}
              status="listed"
              text={e.description}
              maxWidth="100%"
            />
          ))}
        </Flex>
      ) : (
        <P weight="500"> No rejection reason specified.</P>
      )}

      <P color="#DC2929" weight="500" padding="0.5rem 0 0.25rem 0">
        COMMENTS:
      </P>

      {data?.resp?.comments?.[0]?.message ? (
        <P padding="0 0 0.25rem 0">{data.resp.comments[0].message}</P>
      ) : (
        <P weight="500">No comments.</P>
      )}
    </MainModal>
  )
}

export default ReactivateApplicant
