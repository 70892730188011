import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import DashboardButton from './DashboardButton'
import FilterButton from './FilterButton'
import FilterInput from './FilterInput'

import {
  setFilterQueryTerm,
  filterTalents,
  setBulkItems,
} from '../../store/actions/talentActions'

import { debounce } from '../../utils/utils'

const TopFilter = () => {
  const dispatch = useDispatch()
  const { globalSearchTerm } = useSelector((state) => state.talents)

  const debouncedAction = useCallback(
    debounce(() => {
      dispatch(filterTalents())
    }, 3000),
    []
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(filterTalents())
    dispatch(
      setBulkItems({
        selectedAll: false,
      })
    )
  }

  const handleSkillFilter = (e) => {
    if (e.target.value !== '') {
      dispatch(setFilterQueryTerm(e.target.value))
    } else {
      dispatch(setFilterQueryTerm(''))
    }
  }

  const handleClear = () => {
    dispatch(setFilterQueryTerm(''))
    filterTalents()
    dispatch(
      setBulkItems({
        selectedAll: false,
      })
    )
  }

  useEffect(() => {
    debouncedAction()
  }, [globalSearchTerm])

  return (
    <StyledTopFilter>
      <div className="filterWrapper">
        <form onSubmit={handleSubmit} className="inputFilter">
          <FilterInput
            maxW="600px"
            onChange={handleSkillFilter}
            handleClear={handleClear}
            value={globalSearchTerm}
            placeholder="Search Job title, Skills, Company, Location..."
          />
          <DashboardButton type="submit" />
        </form>

        <FilterButton />
      </div>
    </StyledTopFilter>
  )
}

export default TopFilter

const StyledTopFilter = styled.div`
  position: sticky;
  top: 4.2rem;
  z-index: 4;
  width: 100%;
  margin-top: 1.5rem;

  .filterWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9fc;
    padding: 0.25rem 0;
  }

  .inputFilter {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .checkedFilter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9fc;
    max-width: 600px;
    padding: 0.75rem 0;
  }
`
