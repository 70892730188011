/* eslint-disable camelcase */
import React from 'react' // , { useEffect, useState } 
import JobListingFilterSidebar from '../../../../../src/pages/dashboard/job-listing/components/filters/JobListingFiltersSideBar'
import styled from 'styled-components'

const JobListingFilterWrapper = () => {

  return (
    <JobListingWrapperStyled>
      <JobListingFilterSidebar />
    </JobListingWrapperStyled>
  )
}

export default JobListingFilterWrapper

const JobListingWrapperStyled = styled.div`
  width: 100%;
    height: 100%;
  display: flex;
  margin-top: 1rem;
`
