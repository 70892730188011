import React from 'react'

import styled from 'styled-components'

import CollapsibleJobListFilter from '../../../../../../src/pages/dashboard/job-listing/components/filters/CollapsibleJobListFilter'

const JobListingFiltersSideBar = () => {

  return (
    <>
      <JobListingFiltersSideBarStyled>
        <CollapsibleJobListFilter />
      </JobListingFiltersSideBarStyled>
    </>
  )
}

export default JobListingFiltersSideBar

const JobListingFiltersSideBarStyled = styled.aside`
  width: 100%;
  position: sticky;
  top: calc(25vh - 1vh);
  max-height: calc(68vh - 5vh);
  overflow: auto;
  margin-left: 1.5rem;
  margin-top: 1.5rem;

  .clearButton {
    font-size: 0.75rem;
    font-weight: 300;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    color: #1877f2;

    display: none;

    .icon {
      font-size: 1rem;
    }
  }
`
