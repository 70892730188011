import React from 'react'
import Select from 'react-select'

import styled from 'styled-components'

const GroupedSelect = ({ data, selected, onChange, isMulti = false }) => {
  const formatGroupLabel = (data) => (
    <GroupStyles>
      <span>{data.label}</span>
      <GroupBadgeStyles>{data.options.length}</GroupBadgeStyles>
    </GroupStyles>
  )

  return (
    <Select
      isMulti={isMulti}
      defaultValue={isMulti ? data[0] : data[0]?.options[0]}
      value={selected}
      onChange={onChange}
      options={data}
      formatGroupLabel={formatGroupLabel}
    />
  )
}

const GroupStyles = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
`

const GroupBadgeStyles = styled.div`
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  min-width: 1px;
  margin-left: 0.25rem;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
`

export default GroupedSelect
