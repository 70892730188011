import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setCertifications } from '../../../../../store/actions/jobListingActions'

const Certifications = () => {
  const dispatch = useDispatch()
  const { certifications } = useSelector((state) => state.jobListing)  

  const options = [
    "Agile Certified Practitioner (PMI-ACP)",
    "Agricultural Engineer",
    "Architect",
    "Certified Associate in Business Management (CABM)",
    "Certified Associate in Project Management (CAPM)",
    "Certified Financial Planner (CFP)  ",
    "Certified Human Resources Associate (CHRA)",
    "Certified Management Accountant (CMA)",
    "Certified Manager (CM)",
    "Certified Nursing Assistant (CNA)",
    "Certified Public Accountant (CPA)",
    "Certified Public Accountant (CPA)",
    "Certified Sales Leader (CSL)",
    "Certified Sales Professional (CSP)",
    "Certified Scrum Master (CSM)",
    "Certified Scrum Product Owner (CSPO)",
    "Chartered Accountant (CA)",
    "Chartered Financial Analyst (CFA)",
    "Chemical Engineer",
    "Civil Engineer",
    "Criminologist",
    "Customs Broker",
    "Dentist",
    "Electrical Engineer",
    "Electronics Engineer",
    "Environmental Planner",
    "Financial Risk Manager (FRM)",
    "Geodetic Engineer",
    "Google Ads Certification (Search, Display, Video, Shopping)",
    "Google Analytics Individual Qualification (GAIQ)",
    "Guidance Counselor",
    "Hootsuite Social Marketing Certification",
    "HubSpot Inbound Marketing Certification",
    "Interior Designer",
    "Landscape Architect",
    "Lawyer (Attorney)",
    "Lean Six Sigma",
    "Librarian",
    "Licensed Practical Nurse (LPN)",
    "Licensed Professional Teacher",
    "Mechanical Engineer",
    "Medical Assistant (MA)",
    "Medical Technologist",
    "Midwife",
    "Mining Engineer",
    "Occupational Therapist",
    "Pharmacist",
    "Physical Therapist",
    "Physician (MD)",
    "Professional in Human Resources (PHR)",
    "Project Management Professional (PMP)",
    "Psychologist",
    "Psychometrician",
    "Radiologic Technologist",
    "Real Estate Appraiser",
    "Real Estate Broker",
    "Registered Nurse (RN)",
    "Registered Nurse (RN)",
    "Respiratory Therapist",
    "Six Sigma (Green Belt, Black Belt, etc.)",
    "Social Worker",
    "Veterinarian"

  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="certifications"
            title="Certifications"
            value={certifications}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setCertifications(selectedValue === "Choose Option" ? null : selectedValue));
            }}
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default Certifications

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`