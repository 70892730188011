import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect} from '../../../../../../src/components/dashboard/TalentPool/DropdownSelect' // '../../DropdownSelect'
// , MultiDropDownSelect 
import countries from 'all-countries-and-cities-json'
import styled from 'styled-components'

import { setLocation } from '../../../../../store/actions/jobListingActions'

const Location = () => {
  const dispatch = useDispatch()

  const { location } = useSelector((state) => state.jobListing) 

  const [, setCountries] = useState([])

  useEffect(() => {
    Object.keys(countries).forEach((country) => {
      setCountries((prevState) => [
        ...prevState,
        { value: country, label: country },
      ])
    })
  }, [])

  return (
    <div>
        <StyledJobListFilter>
            <div className='.drpdownCountry'>
                <SingleDropdownSelect
                    name="location"
                    title="Country"
                    value={location}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      dispatch(setLocation(selectedValue === "Choose Option" ? null : selectedValue));
                    }} 
                    maxw="350px"
                >
                <option>Choose Option</option>
                {Object.keys(countries).map((country, index) => (
                    <option key={index} value={country}>
                    {country}
                    </option>
                ))}
                </SingleDropdownSelect>
            </div>
        </StyledJobListFilter>

    </div>
  )
}

export default Location

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
    .drpdwnCountry {

    }
    // .drpdownCity {

    // }
`