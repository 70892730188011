import { jobs } from '../types/jobListing'

const intialState = {
  jobListings: [],
  pagination: {
    page: 1,
    pageSize: 10,
    rowCount: 10,
  },
  loading: false,
  modal: '',
  notification: {},
  selectedJob: {},
  jobChanged: false,
  jobUpdated: false,
  jobFilterState: true,
  jobTitle: null,
  jobTitlekeyword: null,
  jobRole: null,
  location: null,
  industry: null,
  experienceLevel: null,
  jobType: null,
  searchTermKeyword: null,
  salaryRange: null,
  minSalaryRange: null,
  maxSalaryRange: null,
  requiredSkills: null,
  requiredSkillsSoftware: null,
  certifications: null,
  postedDate: null,
  minDateRange: null,
  maxDateRange: null,
  benefits: null,
  educationReq: null,
  activeJobFilters: {
    jobFilterItems: 0,
    locationItems: 0,
    industryItems: 0,
    experienceLevelItems: 0,
    jobTypeItems: 0,
    salaryRangeItems: 0,
    requiredSkillsItems: 0,
    postedDateItems: 0,
    benefitsItems: 0,
    educationalReqItems: 0,
  },
}

const jobListingReducer = (state = intialState, action) => {
  switch (action.type) {
    case jobs.GET_JOB_LISTINGS:
      return {
        ...state,
        jobListings: action.payload.jobs,
        pagination: action.payload.pagination,
      }
    case jobs.IS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case jobs.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case jobs.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    case jobs.SELECT_JOB:
      return {
        ...state,
        selectedJob: action.payload,
      }
    case jobs.SET_JOB_CHANGED:
      return {
        ...state,
        jobChanged: !state.jobChanged,
      }
    case jobs.SET_JOB_UPDATED:
      return {
        ...state,
        jobUpdated: action.payload,
      }

    // for filters
    case jobs.SET_TOGGLE_SIDE_JOBFILTERS:
      return {
        ...state,
        jobFilterState: !state.jobFilterState,
      }
    // case jobs.SET_ACTIVE_JOBFILTERS:
    //   return {
    //     ...state,
    //     activeJobFilters: action.payload,
    //   }
    case jobs.SET_JOBTITLE:
      return {
        ...state,
        jobTitle: action.payload,
      }
    case jobs.SET_JOBTITLE_KEYWORD:
      return {
        ...state,
        jobTitlekeyword: action.payload,
      }
    case jobs.SET_JOBROLE:
      return {
        ...state,
        jobRole: action.payload,
      }
    case jobs.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      }
    case jobs.SET_INDUSTRY:
      return {
        ...state,
        industry: action.payload,
      }
    case jobs.SET_EXPERIENCE_LEVEL:
      return {
        ...state,
        experienceLevel: action.payload,
      }
    case jobs.SET_JOBTYPE:
      return {
        ...state,
        jobType: action.payload,
      }
    case jobs.SET_SALARY_RANGE:
      return {
        ...state,
        salaryRange: action.payload,
      }
    case jobs.SET_MINSALARY_RANGE:
      return {
        ...state,
        minSalaryRange: action.payload,
      }
    case jobs.SET_MAXSALARY_RANGE:
      return {
        ...state,
        maxSalaryRange: action.payload,
      }
    case jobs.SET_SEARCHTERM_KEYWORD:
      return {
        ...state,
        searchTermKeyword: action.payload,
      }
    case jobs.SET_REQUIRED_SKILLS:
      return {
        ...state,
        requiredSkills: action.payload,
      }
    case jobs.SET_REQUIRED_SKILLS_SOFTWARE:
      return {
        ...state,
        requiredSkillsSoftware: action.payload,
      }
    case jobs.SET_CERTIFICATIONS:
      return {
        ...state,
        certifications: action.payload,
      }
    case jobs.SET_POSTED_DATE:
      return {
        ...state,
        postedDate: action.payload,
      }
    case jobs.SET_MINDATE_RANGE:
      return {
        ...state,
        minDateRange: action.payload,
      }
    case jobs.SET_MAXDATE_RANGE:
      return {
        ...state,
        maxDateRange: action.payload,
      }
    case jobs.SET_BENEFITS:
      return {
        ...state,
        benefits: action.payload,
      }
    case jobs.SET_EDUCATION_REQ:
      return {
        ...state,
        educationReq: action.payload,
      }
    case jobs.INCREASE_ACTIVEJOB_FILTER:
      return {
        ...state,
        activeJobFilters: {
          ...state.activeJobFilters,
          [action.payload.prop]: action.payload.amount,
        },
      }
    default:
      return state
  }
}

export default jobListingReducer
