import React, { useEffect } from 'react'  // , useState
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'

import { setPostedDate, setMinDateRange, setMaxDateRange } from '../../../../../store/actions/jobListingActions'

const PostedDate = () => {
  const dispatch = useDispatch()
  const { postedDate, minDateRange, maxDateRange } = useSelector((state) => state.jobListing)  
  // const [startDate, setStartDate] = useState(new Date())
  // const [endDate, setEndDate] = useState(new Date())

  const options = [
    "Last 24 Hours",
    "Last 3 Days",
    "Last Week",
    "Last Month",
    "Custom Range"
  ]

  const handleStartDate = (e) => {
    // setStartDate(e)
    dispatch(setMinDateRange(e));
    // if (postedDate === "Custom Range") {
    //   dispatch(setMinDateRange(`${formatDate(e)} - ${formatDate(endDate)}`));
    // }
  }

  const handleEndDate = (e) => {
    // setEndDate(e)
    dispatch(setMinDateRange(e));
    // if (postedDate === "Custom Range") {
    //   dispatch(setMaxDateRange(`${formatDate(startDate)} - ${formatDate(e)}`));
    // }
  }

  // const formatDate = (date) => {
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  //   return date.toLocaleDateString(undefined, options);
  //   // const year = date.getFullYear();
  //   // const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  //   // const day = date.getDate().toString().padStart(2, '0');
  //   // return `${year}-${month}-${day}`;
  
  // }

  const getOneMonthAgo = (date) => {
    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() - 1)
    return newDate
  }

  const handlePostedDate = (e) => {
    const selectedValue = e.target.value;
    dispatch(setPostedDate(selectedValue === "Choose Option" ? null : selectedValue));
    if (selectedValue !== "Custom Range") {
      // Reset the start and end dates when selecting a different option
      // setStartDate(new Date())
      // setEndDate(new Date())
      dispatch(setMinDateRange(null))
      dispatch(setMaxDateRange(null))
    } else {
      const oneMonthAgo = getOneMonthAgo(new Date())
      // setStartDate(oneMonthAgo)
      // setEndDate(new Date())
      dispatch(setMinDateRange(oneMonthAgo))
      dispatch(setMaxDateRange(new Date()))
    }
  }

  useEffect(() => {
    // if (postedDate !== "Custom Range") {
    //   dispatch(setMinDateRange(null))
    //   dispatch(setMaxDateRange(null))
    // }
  }, [postedDate, minDateRange, maxDateRange])

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="postedDate"
            title="Posted Date"
            value={postedDate}
            // onChange={(e) => {
            //   const selectedValue = e.target.value;
            //   dispatch(setPostedDate(selectedValue === "Choose Option" ? '' : selectedValue));
            // }} 
            onChange={(e) => handlePostedDate(e)}
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
        { postedDate === "Custom Range" && (
          <div className='custom-range'>
            <label htmlFor="startDate">From: </label>
            <ReactDatePicker
              id="startDate"
              // selected={startDate}
              selected={minDateRange}
              onChange={handleStartDate}
              dateFormat="MMMM dd, yyyy"
              placeholderText="Enter Start Date"
              className="react-date-picker"
            />
            <br></br>
            <label htmlFor="endDate">To: </label>
            <ReactDatePicker
              id="endDate"
              // selected={endDate}
              selected={maxDateRange}
              onChange={handleEndDate}
              dateFormat="MMMM dd, yyyy"
              placeholderText="Enter End Date"
              className="react-date-picker"
            />
          </div>
        )}
    </StyledJobListFilter>
  )
}

export default PostedDate

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;

    .custom-range{

    }
`