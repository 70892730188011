import React, { useEffect } from 'react'
import { useSelector  } from 'react-redux' 

import styled from 'styled-components'

import JobListingFilterWrapper from './JobListingFilterWrapper'
import JobListingWrapper from './JobListingWrapper'

const JobGridLayoutWithFilters = ({ children }) => {
  const { jobFilterState  } = useSelector((state) => state.jobListing) 
  useEffect(() => {
  }, [children])
  return (
    <>
    {jobFilterState ? (
      <StyledJobGridLayout>
        <StyledJobFilters>
          <JobListingFilterWrapper/>
        </StyledJobFilters>
        <StyledJobListAndDetails>
          <JobListingWrapper />
          <JobListingContentWrapper>{children}</JobListingContentWrapper>
        </StyledJobListAndDetails>
      </StyledJobGridLayout>
    ) : (
      <StyledJobGridLayoutClosedFilter>
        <StyledJobListAndDetails>
          <JobListingWrapper />
          <JobListingContentWrapper>{children}</JobListingContentWrapper>
        </StyledJobListAndDetails>
      </StyledJobGridLayoutClosedFilter>
    )}
    </>
  )
}

export default JobGridLayoutWithFilters

const StyledJobGridLayout = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 2rem;
`

const StyledJobGridLayoutClosedFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const StyledJobFilters = styled.div`
  width: 100%;
  height: calc(100vh - 18rem);
`

const StyledJobListAndDetails = styled.div`
  width: 100%;
  height: calc(100vh - 9rem);
  display: grid;
  grid-template-columns: 1fr 2fr;
`

const JobListingContentWrapper = styled.div`
  width: 100%;
  height: 70%;
`