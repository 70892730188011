import React, { useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import useFetch from '../../../hooks/useFetch'

import { H2, H6, P } from '../../../style/Typography.style'
import CopyToClipboard from '../../CopyToClipboard'
import Flex from '../../layouts/Flex'
import CVImage from './CVImage'
import TechnicalSkillCard from './TechnicalSkillCard'
import WorkExperienceCard from './WorkExperienceCard'
import CharRefCard from './CharacterRefCard'

const ApplicantDetails = ({ talent }) => {
  const [professionalHeadline, setProfessionalHeadline] = useState('')
  const meta = talent && talent.meta ? JSON.parse(talent.meta) : null
  const experienceState = (meta && meta.experienceState) || null
  const skills = meta?.fullSkills || []
  const technicalSkills = skills[0] || []
  const characterReference =
    talent && talent.character_reference
      ? JSON.parse(talent.character_reference)
      : []
  const { uploadedCv } = useSelector((state) => state.talents)

  const { data: remoteData, refetch } = useFetch(
    `user/${talent.user_id}/remote-work`
  )

  const remoteDataResp = remoteData && remoteData?.resp

  const compSpec =
    remoteDataResp && remoteData?.resp?.computer_specification !== ''
      ? JSON.parse(remoteData?.resp?.computer_specification)
      : null

  const pheripheral =
    remoteDataResp && remoteData?.resp?.peripherals !== ''
      ? JSON.parse(remoteData?.resp?.peripherals)
      : null

  const ethics =
    remoteDataResp && remoteData?.resp?.meta !== ''
      ? JSON.parse(remoteData?.resp?.meta)
      : null

  useEffect(() => {
    refetch()
  }, [talent])

  useEffect(() => {
    const lists = []

    talent &&
      talent.professional_headline &&
      JSON.parse(talent.professional_headline) &&
      JSON.parse(talent.professional_headline).length > 0 &&
      JSON.parse(talent.professional_headline).forEach(({ item }) => {
        lists.push(item)
      })
    setProfessionalHeadline(lists.splice(' • ').join(' • '))
  }, [])

  // FIXME: Theres a bug that does not reflect the correct applicant profile when changing intermittently

  return (
    <CV>
      {uploadedCv && uploadedCv.public_id && uploadedCv.format === 'pdf' ? (
        <>
          {typeof uploadedCv === 'object' &&
            [...Array(uploadedCv?.pages).keys()].map((page) => (
              <CVImage
                key={page}
                url={uploadedCv?.url?.slice(0, -3) + 'jpg'}
                pageNumber={page + 1}
              />
            ))}
        </>
      ) : (
        <>
          <H2 mb="0.75rem" align="left" weight="500" size="1.5rem">
            Applicant’s CV
          </H2>
          <H6 mb="0.5rem" weight="500">
            {talent?.name || 'Talent Name'}
          </H6>
          <P>{professionalHeadline}</P>

          <P
            marginTop="1.5rem"
            marginBottom="0.6rem"
            size="0.8rem"
            weight="700"
          >
            CONTACT DETAILS
          </P>
          <Flex margin="0 0 0.5rem 0" items="center" gap="0.75rem">
            <P>{talent?.email || 'Talent Email'}</P>
            <CopyToClipboard text={talent?.email || 'Talent Email'} />
          </Flex>

          <Flex margin="0 0 0.5rem 0" items="center" gap="0.75rem">
            <P>{talent?.phone_number || 'Talent Contact'}</P>
            <CopyToClipboard text={talent?.phone_number || 'Talent Contact'} />
          </Flex>

          {/* PERSONAL PROFILE */}
          <P
            marginTop="1.5rem"
            marginBottom="0.6rem"
            size="0.8rem"
            weight="700"
          >
            PERSONAL PROFILE
          </P>
          <P
            marginBottom="0.5rem"
            dangerouslySetInnerHTML={{
              __html: talent?.professional_summary
                ? JSON.parse(talent?.professional_summary || '')
                : '',
            }}
          />

          {/* PERSONAL PROFILE */}
          <P
            marginTop="1.5rem"
            marginBottom="0.6rem"
            size="0.8rem"
            weight="700"
          >
            WORK EXPERIENCE
          </P>

          {experienceState && experienceState.length > 0 ? (
            experienceState.map(
              (
                {
                  companyName,
                  jobTitle,
                  jobDescriptionHtml,
                  experienceFrom,
                  experienceTo,
                  companyLocation,
                  workType,
                },
                index
              ) => (
                <WorkExperienceCard
                  key={index}
                  from={experienceFrom}
                  to={experienceTo}
                  company={companyName}
                  location={companyLocation}
                  description={jobDescriptionHtml}
                  title={jobTitle}
                />
              )
            )
          ) : (
            <P>No experience</P>
          )}

          {/* Technical Skills */}
          <P
            size="0.8125rem"
            weight="500"
            marginTop="2rem"
            textTransform="uppercase"
            marginBottom="1rem"
          >
            TECHNICAL SKILLS
          </P>
          {technicalSkills &&
            technicalSkills.map(({ skill, experience, id }) => (
              <TechnicalSkillCard
                key={id}
                skill={skill}
                experience={experience}
              />
            ))}

          {/* character reference */}
          <P
            marginTop="1.5rem"
            marginBottom="0.6rem"
            size="0.8rem"
            weight="700"
          >
            CHARACTER REFERENCES
          </P>
          {characterReference && characterReference.length > 0 ? (
            characterReference.map(
              ({ id, crName, crEmail, crPhone }, index) => (
                <CharRefCard
                  key={id}
                  crName={crName}
                  crEmail={crEmail}
                  crPhone={crPhone}
                />
              )
            )
          ) : (
            <P>No Character Reference</P>
          )}
        </>
      )}

      {uploadedCv && remoteDataResp && (
        <div className="wfh">
          <P
            textTransform="uppercase"
            marginBottom="1rem"
            weight="500"
            size=".875rem"
          >
            Wfh setup
          </P>

          <div className="grid">
            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Operating System
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.os ? compSpec?.os : 'not specified'}
              </P>
            </TwoGrid>
            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Processor
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.processor ? compSpec?.processor : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Installed Ram
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.ram ? `${compSpec?.ram} RAM` : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Os Version
              </P>
              <P weight="500" size=".75rem">
                {compSpec?.osVersion ? compSpec?.osVersion : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                WebCam
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.webCamera
                  ? pheripheral?.webCamera
                  : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Headset
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.headSet ? pheripheral?.headSet : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Microphone
              </P>
              <P weight="500" size=".75rem">
                {pheripheral?.microphone
                  ? pheripheral?.microphone
                  : 'not specified'}
              </P>
            </TwoGrid>
          </div>

          <P
            textTransform="uppercase"
            marginTop="2rem"
            weight="500"
            size=".875rem"
          >
            Remote Work Ethics
          </P>

          <div className="grid">
            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Work Area
              </P>
              <P weight="500" size=".75rem">
                {ethics?.workStation ? ethics?.workStation : 'not specified'}
              </P>
            </TwoGrid>
            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Device
              </P>
              <P weight="500" size=".75rem">
                {ethics?.device ? ethics?.device : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Schedule
              </P>
              <P weight="500" size=".75rem">
                {ethics?.workSchedule ? ethics?.workSchedule : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Shifts
              </P>
              <P weight="500" size=".75rem">
                {ethics?.workShift ? ethics?.workShift : 'not specified'}
              </P>
            </TwoGrid>

            <TwoGrid className="two-grid">
              <P weight="300" size=".75rem">
                Time Tracker
              </P>
              <P weight="500" size=".75rem">
                {ethics?.timeTracker ? ethics?.timeTracker : 'not specified'}
              </P>
            </TwoGrid>
          </div>
        </div>
      )}
    </CV>
  )
}

export default memo(ApplicantDetails)

const TwoGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  max-width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
`

const CV = styled.div`
  height: calc(100vh - 5rem);
  overflow-y: auto;
`
