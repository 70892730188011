import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setRequiredSkillsSoftware } from '../../../../../store/actions/jobListingActions'

const Software = () => {
  const dispatch = useDispatch()
  const { requiredSkillsSoftware } = useSelector((state) => state.jobListing)  

  const options = [
    ".NET",
    "ActiveCampaign",
    "Acumatica Cloud ERP",
    "Adobe Acrobat Pro",
    "Adobe After Effects",
    "Adobe Analytics",
    "Adobe Animate",
    "Adobe Audition",
    "Adobe Bridge",
    "Adobe Character Animator",
    "Adobe Dreamweaver",
    "Adobe Express (formerly Spark)",
    "Adobe Fresco",
    "Adobe Illustrator",
    "Adobe InDesign",
    "Adobe Lightroom",
    "Adobe Media Encoder",
    "Adobe Photoshop",
    "Adobe Premiere Pro",
    "Adobe Stock",
    "Adobe XD",
    "Adobe XD",
    "Agile CRM",
    "Agorapulse",
    "Ahrefs",
    "Airtable",
    "Amazon Advertising",
    "Angular",
    "Animoto",
    "Asana",
    "Atom",
    "Autopilot",
    "AWeber",
    "Awin",
    "AWS",
    "Basecamp",
    "Benchmark Email",
    "Bitbucket",
    "Biteable",
    "Bitrix24",
    "Brand24",
    "Brandwatch",
    "Buffer",
    "BuzzSumo",
    "C#",
    "Calendly",
    "Campaign Monitor",
    "Canva",
    "Carrd",
    "Chartbeat",
    "CJ Affiliate (Commission Junction)",
    "Clearscope",
    "ClickFunnels",
    "ClickUp",
    "Close CRM",
    "Constant Contact",
    "ConvertKit",
    "Copper CRM",
    "Creatio CRM",
    "Crello",
    "CSS",
    "Deltek ERP",
    "DigitalOcean",
    "Django",
    "Drupal",
    "Epicor ERP",
    "Express.js",
    "Facebook Ads Manager",
    "Figma",
    "Figma",
    "Firebase",
    "Firebase Hosting",
    "Flask",
    "FreeAgent",
    "FreshBooks",
    "Freshsales",
    "Git",
    "GitHub",
    "GitLab",
    "Google Ads (Search, Display, Video)",
    "Google Analytics",
    "Google Cloud Platform (GCP)",
    "Google Sheets",
    "Google Workspace",
    "Grammarly",
    "Hootsuite",
    "Hootsuite Insights",
    "Hotjar",
    "HTML",
    "HubSpot",
    "HubSpot CRM",
    "IFS ERP",
    "Impact",
    "Infor ERP",
    "Insightly",
    "Instagram Ads",
    "Instapage",
    "Java",
    "JavaScript",
    "Jira",
    "Joomla",
    "Kashoo",
    "Keap (formerly Infusionsoft)",
    "Keap (formerly Infusionsoft)",
    "Kissmetrics",
    "Klaviyo",
    "Landingi",
    "Laravel",
    "Later",
    "Leadpages",
    "LinkedIn Ads",
    "LiveChat",
    "Loomly",
    "Magento",
    "Mailchimp",
    "Mailchimp",
    "Marketo",
    "MeetEdgar",
    "Microsoft Azure",
    "Microsoft Dynamics 365",
    "Microsoft Dynamics 365 ERP",
    "Microsoft Dynamics 365 Finance",
    "Microsoft Excel",
    "Microsoft Office",
    "Microsoft Project",
    "Microsoft SQL Server",
    "Microsoft Suite",
    "Mixpanel",
    "Monday Sales CRM",
    "Monday.com",
    "MongoDB",
    "Moosend",
    "Moz",
    "MYOB",
    "MySQL",
    "Netlify",
    "NetSuite Accounting",
    "NetSuite CRM",
    "NetSuite ERP",
    "Next.js",
    "Nimble",
    "Node.js",
    "Notion",
    "Nuxt.js",
    "Odoo ERP",
    "Ontraport",
    "Oracle CRM",
    "Oracle ERP Cloud",
    "Oracle Financials Cloud",
    "Pardot (Salesforce Marketing Cloud)",
    "Patriot Accounting",
    "PHP",
    "Pipedrive",
    "PostgreSQL",
    "Postman",
    "Priority ERP",
    "ProofHub",
    "Python",
    "Qualtrics",
    "QuickBooks",
    "QuickBooks Enterprise",
    "Rakuten Advertising",
    "React.js",
    "Ruby",
    "Ruby on Rails",
    "Sage Intacct",
    "Sage X3",
    "Salesforce",
    "SAP Business One",
    "SAP CRM",
    "SAP ERP",
    "Semrush",
    "Sendinblue",
    "Sendinblue",
    "ShareASale",
    "Shopify",
    "Smartsheet",
    "Sprout Social",
    "Sprout Social",
    "SQLite",
    "Squarespace",
    "Sublime Text",
    "SugarCRM",
    "SurferSEO",
    "SurveyMonkey",
    "Svelte",
    "Syspro ERP",
    "Talkwalker",
    "Tally ERP",
    "Teamwork",
    "TikTok Ads",
    "Trello",
    "Twitter Ads",
    "Typeform",
    "TypeScript",
    "Unbounce",
    "Vercel",
    "Vidyard",
    "Vimeo",
    "Visual Studio Code (VS Code)",
    "VoIP",
    "Vue.js",
    "Wave Accounting",
    "Webflow",
    "WebStorm",
    "Wistia",
    "Wix",
    "WordPress",
    "Workday ERP",
    "Workfront",
    "Wrike",
    "Xero",
    "Yoast SEO (WordPress plugin)",
    "YouTube",
    "Zendesk Sell",
    "Zoho Books",
    "Zoho CRM",
    "Zoho Projects"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="requiredSkillsSoftware"
            title="Software"
            value={requiredSkillsSoftware}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setRequiredSkillsSoftware(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default Software

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`