import React, { useState }  from 'react' 
import styled from 'styled-components'

import {
    CollapsibleComponent,
    CollapsibleHead,
    CollapsibleContent,
} from 'react-collapsible-component'

import { useDispatch, useSelector } from 'react-redux' 
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

import JobTitleFilter  from '../../../../../../src/pages/dashboard/job-listing/components/filters/JobTitle'     
import JobTitleKeywordFilter  from '../../../../../../src/pages/dashboard/job-listing/components/filters/JobTitleKeyword' 
import JobRoleFilter  from '../../../../../../src/pages/dashboard/job-listing/components/filters/JobRole' 
import LocationFilter  from '../../../../../../src/pages/dashboard/job-listing/components/filters/Location'
import IndustryFilter  from '../../../../../../src/pages/dashboard/job-listing/components/filters/Industry'
import ExperienceLevel  from '../../../../../../src/pages/dashboard/job-listing/components/filters/ExperienceLevel'
import JobType  from '../../../../../../src/pages/dashboard/job-listing/components/filters/JobType'
import SalaryRange  from '../../../../../../src/pages/dashboard/job-listing/components/filters/SalaryRange'
import RequiredSkills  from '../../../../../../src/pages/dashboard/job-listing/components/filters/RequiredSkills'
import Software  from '../../../../../../src/pages/dashboard/job-listing/components/filters/Software'
import Certifications  from '../../../../../../src/pages/dashboard/job-listing/components/filters/Certifications'
import PostedDate  from '../../../../../../src/pages/dashboard/job-listing/components/filters/PostedDate'
import Benefits  from '../../../../../../src/pages/dashboard/job-listing/components/filters/Benefits'
import EducationalReq  from '../../../../../../src/pages/dashboard/job-listing/components/filters/EducationalReq'

import {
  filterJobList,
  setJobTitle,
  setJobTitleKeyword,
  setJobRole,
  setLocation,
  setIndustry,
  setExperienceLevel,
  setJobType,
  setSalaryRange,
  setMinimumSalary,
  setMaximumSalary,
  setRequiredSkills,
  setRequiredSkillsSoftware,
  setCertifications,
  setPostedDate,
  setMinDateRange,
  setMaxDateRange,
  setBenefits,
  setEducationReq,
  calculateActiveJobFilters,
} from '../../../../../store/actions/jobListingActions'

const CollapsibleJobListFilter = () => {
  const [collapsibleJobFilterHead, setCollapsibleJobFilterHead] = useState({})
  const dispatch = useDispatch()
  const { activeJobFilters } = useSelector((state) => state.jobListing)
  const {
      searchTermKeyword, jobTitle, jobTitlekeyword, jobRole, location, industry, experienceLevel, jobType,
      salaryRange, minSalaryRange, maxSalaryRange,
      requiredSkills, requiredSkillsSoftware, certifications, 
      postedDate, minDateRange, maxDateRange,
      benefits, educationReq
  } = useSelector((state) => state.jobListing)  

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant
  const companyDetails = role === 'poc' && JSON.parse(currentUser)?.companyDetails

  const handleCollapsibleJobFilterHead = (filter) => {
    setCollapsibleJobFilterHead((prevState) => ({
      ...prevState,
      [filter]: !collapsibleJobFilterHead[filter],
    }))
  }

  const handleApplyFilters = () => {
    dispatch(calculateActiveJobFilters())

    let salaryRangeValue = null;
    let postedby = null;
    let company = null;

    if(salaryRange !== null){
      salaryRangeValue = minSalaryRange + ',' + maxSalaryRange //*
    }

    const jobTitleValues = (jobTitle || jobTitlekeyword || jobRole) 
      ? { job_Title: jobTitle, job_Titlekeyword: jobTitlekeyword, job_Role: jobRole }
      : null;
    
    const reqSkillsAndCerts = (requiredSkills || requiredSkillsSoftware || certifications) 
      ? { reqSkill: requiredSkills, reqSoftware: requiredSkillsSoftware, certification: certifications }
      : null;

    if (role === 'recruiter' && !recruiterAssistant) {
      postedby = userId
    } else if (role === 'poc') {
      company = JSON.stringify(companyDetails)
    }

    const params = {
      searchJobBy: searchTermKeyword && searchTermKeyword !== null ? searchTermKeyword : undefined,
      salary_basis: salaryRangeValue && salaryRangeValue !== null ? salaryRangeValue : undefined,
      date_posted: postedDate && postedDate !== null ? postedDate : undefined,            
      minDateRange: postedDate && postedDate !== null ? minDateRange : undefined,           
      maxDateRange: postedDate && postedDate !== null ? maxDateRange : undefined,          
      country: location && location !== null ? location : undefined,
      job_type: jobType && jobType !== null ? jobType : undefined,                
      job_terms: jobTitleValues && jobTitleValues !== null ? jobTitleValues : undefined,        
      industry: industry && industry !== null ? industry : undefined,
      skills_terms: reqSkillsAndCerts && reqSkillsAndCerts !== null ? reqSkillsAndCerts : undefined,  
      benefits: benefits && benefits !== null ? benefits : undefined,
      educationReq: educationReq && educationReq !== null ? educationReq : undefined,
      experienceLevel: experienceLevel && experienceLevel !== null ? experienceLevel : undefined,
      posted_by: postedby && postedby !== null ? postedby : undefined,
      companyDetails: company && company !== null ? company : undefined,
      page: 1,
    }
    
    dispatch(filterJobList(params))
  }

  const handleClearFilters = () => {
    dispatch(setJobTitle(''))
    dispatch(setJobTitleKeyword(''))
    dispatch(setJobRole(''))
    dispatch(setLocation(''))
    dispatch(setIndustry(''))
    dispatch(setExperienceLevel(''))
    dispatch(setJobType(''))
    dispatch(setSalaryRange(null))
    dispatch(setMinimumSalary(null))
    dispatch(setMaximumSalary(null))
    dispatch(setRequiredSkills(''))
    dispatch(setRequiredSkillsSoftware(''))
    dispatch(setCertifications(''))
    dispatch(setPostedDate(''))
    dispatch(setMinDateRange(''))
    dispatch(setMaxDateRange(''))
    dispatch(setBenefits(''))
    dispatch(setEducationReq(''))
    dispatch(calculateActiveJobFilters())
  }

  return (
    <CollapsibleComponent>
        {/* --- job title filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('jobFilterItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.jobFilterItems}>
            <p>Job Title</p>
            {activeJobFilters?.jobFilterItems > 0 && (
              <span>{activeJobFilters?.jobFilterItems}</span>
            )}
            {collapsibleJobFilterHead?.jobFilterItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.jobFilterItems}>
          <JobTitleFilter></JobTitleFilter>
          <JobTitleKeywordFilter></JobTitleKeywordFilter>
          <JobRoleFilter></JobRoleFilter>
        </StyledCollapsibleFilterContent>

        {/* --- location filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('locationItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.locationItems}>
            <p>Location</p>
            {activeJobFilters?.locationItems > 0 && (
              <span>{activeJobFilters?.locationItems}</span>
            )}
            {collapsibleJobFilterHead?.locationItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.locationItems}>
          <LocationFilter></LocationFilter>
        </StyledCollapsibleFilterContent>

        {/* --- industry filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('industryItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.industryItems}>
            <p>Industry</p>
            {activeJobFilters?.industryItems > 0 && (
              <span>{activeJobFilters?.industryItems}</span>
            )}
            {collapsibleJobFilterHead?.industryItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.industryItems}>
          <IndustryFilter></IndustryFilter>
        </StyledCollapsibleFilterContent>

        {/* --- experience level filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('experienceLevelItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.experienceLevelItems}>
            <p>Experience Level</p>
            {activeJobFilters?.experienceLevelItems > 0 && (
              <span>{activeJobFilters?.experienceLevelItems}</span>
            )}
            {collapsibleJobFilterHead?.experienceLevelItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.experienceLevelItems}>
          <ExperienceLevel></ExperienceLevel>
        </StyledCollapsibleFilterContent>

        {/* --- job type filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('jobTypeItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.jobTypeItems}>
            <p>Job Type</p>
            {activeJobFilters?.jobTypeItems > 0 && (
              <span>{activeJobFilters?.jobTypeItems}</span>
            )}
            {collapsibleJobFilterHead?.jobTypeItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.jobTypeItems}>
          <JobType></JobType>
        </StyledCollapsibleFilterContent>

        {/* --- salary range filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('salaryRangeItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.salaryRangeItems}>
            <p>Salary Range</p>
            {activeJobFilters?.salaryRangeItems > 0 && (
              <span>{activeJobFilters?.salaryRangeItems}</span>
            )}
            {collapsibleJobFilterHead?.salaryRangeItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead>
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.salaryRangeItems}>
          <SalaryRange></SalaryRange>
        </StyledCollapsibleFilterContent>

        {/* --- required skills filter --- */}       
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('requiredSkillsItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.requiredSkillsItems}>
            <p>Required Skills</p>
            {activeJobFilters?.requiredSkillsItems > 0 && (
              <span>{activeJobFilters?.requiredSkillsItems}</span>
            )}
            {collapsibleJobFilterHead?.requiredSkillsItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead> 
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.requiredSkillsItems}>
          <RequiredSkills></RequiredSkills>
          <Software></Software>
          <Certifications></Certifications>
        </StyledCollapsibleFilterContent>

        {/* --- posted date filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('postedDateItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.postedDateItems}>
            <p>Posted Date</p>
            {activeJobFilters?.postedDateItems > 0 && (
              <span>{activeJobFilters?.postedDateItems}</span>
            )}
            {collapsibleJobFilterHead?.postedDateItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead> 
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.postedDateItems}>
          <PostedDate></PostedDate>
        </StyledCollapsibleFilterContent>

        {/* --- benefits filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('benefitsItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.benefitsItems}>
            <p>Benefits</p>
            {activeJobFilters?.benefitsItems > 0 && (
              <span>{activeJobFilters?.benefitsItems}</span>
            )}
            {collapsibleJobFilterHead?.benefitsItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead> 
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.benefitsItems}>
          <Benefits></Benefits>
        </StyledCollapsibleFilterContent>

        {/* --- educational requirements filter --- */}
        <StyledJobFilterHead onClick={() => handleCollapsibleJobFilterHead('educationalReqItems')}>
          <CollapsibleHead isExpanded={collapsibleJobFilterHead?.educationalReqItems}>
            <p>Educational Requirements</p>
            {activeJobFilters?.educationalReqItems > 0 && (
              <span>{activeJobFilters?.educationalReqItems}</span>
            )}
            {collapsibleJobFilterHead?.educationalReqItems ? <SlArrowUp /> : <SlArrowDown />}
          </CollapsibleHead>
        </StyledJobFilterHead> 
        <StyledCollapsibleFilterContent isExpanded={collapsibleJobFilterHead?.educationalReqItems}>
          <EducationalReq></EducationalReq>
        </StyledCollapsibleFilterContent>

        <ApplyJobFilterButton onClick={handleApplyFilters}>
          Apply Filters
        </ApplyJobFilterButton>
        <ClearJobFilterButton onClick={handleClearFilters}>
          Clear Filters
        </ClearJobFilterButton>

    </CollapsibleComponent>
  )
}

export default CollapsibleJobListFilter

const StyledJobFilterHead = styled.div`
  .accordion-head {
    display: flex;
    justify-content: space-between;
    background: none;
    color: #1a1a1a;
    padding-left: 0;
  }
`

const StyledCollapsibleFilterContent = styled(CollapsibleContent)`
  overflow: visible;
`

const ApplyJobFilterButton = styled.button`
  height: 2.1rem;
  width: 100%;
  background: #1a1a1a;
  border: none;
  outline: none;
  border-radius: 3px;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`

const ClearJobFilterButton = styled.button`
  height: 2.1rem;
  width: 100%;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 3px;
  color: #31374f;
  font-size: 0.75rem;
  font-weight: 400;
  cursor: pointer;
`