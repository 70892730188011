import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setJobRole } from '../../../../../store/actions/jobListingActions'

const JobRole = () => {
  const dispatch = useDispatch()
  const { jobRole } = useSelector((state) => state.jobListing)  

  const options = [
    "3D Graphic Designer",
    "Account Manager",
    "Accountant",
    "Accounting Associate",
    "Accounting Operations Specialist",
    "Accounts Officer",
    "Accounts Payable Officer",
    "Admin Assistant",
    "Administrative Assistant",
    "Administrative Manager",
    "Ads Specialist",
    "Agent/Loan Processing Assistant",
    "Analytics Specialist",
    "Animator",
    "AP/AR Specialist",
    "Appointment Setter",
    "ASP .Net Developer",
    "Asset Management Analyst",
    "Back End Developer",
    "Bookkeeper",
    "Broker Support",
    "Business Development Associate",
    "Client Delivery Manager",
    "Collections Officer",
    "Completion Engineer",
    "Creative Designer",
    "Credit Control Officer",
    "Customer Service Representative",
    "Customer Success Manager",
    "Customer Support Analyst",
    "Customer Support Specialist",
    "Data Analyst",
    "Data Processor",
    "Digital Account Coordinator",
    "Digital Marketing Analyst",
    "Digital Marketing Coordinator",
    "eCommerce Assistant",
    "eCommerce Manager",
    "Estimator Assistant",
    "Events Coordinator",
    "Executive Assistant",
    "Finance Analyst",
    "Finance Head",
    "Front End Developer",
    "Full Stack Developer",
    "General Virtual Assistant",
    "Graphic Designer",
    "Head of Growth",
    "Head of People and Culture",
    "Host Account Manager",
    "HR Administrator",
    "HR and Training Coordinator",
    "Invoicing Specialist",
    "Laravel Developer",
    "Legal Administrative Assistant",
    "Legal Virtual Assistant",
    "Logistics Coordinator",
    "Marketing and Social Media Coordinator",
    "Marketing Content Writer",
    "Marketing Operations Specialist",
    "Media Buyer",
    "Multimedia Designer",
    "Onboarding Coordinator",
    "Operations Analyst",
    "Operations Analyst",
    "Operations and Maintenance Engineer",
    "Operations Manager",
    "Operations Support",
    "Outbound Sales Support",
    "Paralegal",
    "Payroll Officer",
    "Phone Operator",
    "Phone Receptionist",
    "Practitioner Support",
    "Project Coordinator",
    "Project Manager",
    "QA Specialist",
    "QA Tester",
    "Real Estate Financial Analyst",
    "Real Estate Underwriter Analyst",
    "Recruiter",
    "Recruitment Assistant",
    "Sales and Marketing Administrator",
    "Sales Development Representative",
    "Sales Manager",
    "Sales Support",
    "Senior Executive Assistant",
    "SEO Specialist",
    "SEO Specialist",
    "Shopify Developer",
    "Shopify Developer",
    "Social Media Specialist",
    "Software Engineer",
    "Staff Associate Coach",
    "Technical Consultant",
    "Technical Product Manager",
    "Technical Support",
    "Video Editor",
    "Virtual Assistant",
    "Virtual Assistant",
    "WebFlow Developer",
    "Website Developer",
    "Wordpress Developer"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="jobRole"
            title="Job Role"
            value={jobRole}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setJobRole(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default JobRole

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`