import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setJobTitleKeyword } from '../../../../../../src/store/actions/jobListingActions'

const JobTitleKeyword = () => {
  const dispatch = useDispatch()
  const { jobTitlekeyword } = useSelector((state) => state.jobListing)  

  const options = [
    "Remote",
    "Senior",
    "Junior",
    "Project-based"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="jobTitleKeyword"
            title="Job Title Keywords"
            value={jobTitlekeyword}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setJobTitleKeyword(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default JobTitleKeyword

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`