import React from 'react'

import styled from 'styled-components'

import SearchResult from './SearchResult'
import EmptyDetails from '../EmptyDetails'

const SearchResults = ({ data = [], onSearchResultClicked }) => {
  return (
    <Wrapper>
      {data.length > 0 ? (
        data.map((e, i) => (
          <SearchResult
            onSearchResultClicked={() => {
              onSearchResultClicked(e)
            }}
            key={i}
            id={e.id}
            name={e.applicantName}
            email={e.applicantEmail}
            role={e.role}
            stage={e.stageName}
            recruiter={e.recruiterName}
            channel={e.channel}
            client={e.client}
          />
        ))
      ) : (
        <EmptyDetails message="No search results found." height="100%" />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 520px;
`

export default SearchResults
