import React from 'react'
import styled from 'styled-components'

const EmptyDetails = ({ message = 'Cv is currently empty', height }) => {
  return <StyledEmptyDetails height={height}>{message}</StyledEmptyDetails>
}

export default EmptyDetails

const StyledEmptyDetails = styled.div`
  width: 100%;
  height: ${({ height }) => height || '300px'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  color: #1a1a1a;
`
