import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setEducationReq } from '../../../../../store/actions/jobListingActions'

const EducationalReq = () => {
  const dispatch = useDispatch()
  const { educationReq } = useSelector((state) => state.jobListing)  

  const options = [
    "High school diploma",
    "Bachelor's degree",
    "Master's degree",
    "PhD"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="educationReq"
            title="Educational Requirements"
            value={educationReq}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setEducationReq(selectedValue === "Choose Option" ? null : selectedValue));
            }}            
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default EducationalReq

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`