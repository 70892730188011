import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import moment from 'moment'

import { P } from '../../../../style/Typography.style'

import RadioButton from '../../../../components/dashboard/controls/RadioButton'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import ModalHead from '../../../../components/dashboard/modal/ModalHead'
import { SingleDropdownSelect } from '../../../../components/dashboard/TalentPool/DropdownSelect'
import GroupedSelect from '../../../../components/GroupedSelect'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { setModal } from '../../../../store/actions/listsActions'
import {
  setInterviews,
  setUpdatedStage,
  setRefreshList,
  setApplicants,
} from '../../../../store/actions/recruitersActions'
import { getTalent } from '../../../../store/actions/talentActions'

import { groupOptionsByType } from '../../../../utils/utils'

const RejectApplicant = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [rejectStatus, setRejectStatus] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [scheduledloading, setScheduledLoading] = useState(false)
  const [currStage, setCurrStage] = useState(0)
  const [jobId, setJobId] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [jobItems, setJobItems] = useState()
  const [reasonsForRejection, setReasonsForRejection] = useState([])
  const [reasonForRejection, setReasonForRejection] = useState([])

  const { selectedInterview, interviews, applicants } = useSelector(
    (state) => state.requisitions
  )
  const { modal } = useSelector((state) => state.lists)

  const addDaysToDate = (date) => {
    date.setDate(date.getDate() + 2)
    return date
  }

  const reasonIds = useMemo(() => {
    if (Array.isArray(reasonForRejection)) {
      return reasonForRejection.map((e) => e.value)
    }

    return [reasonForRejection.value]
  }, [reasonForRejection])

  const handleRejectApplicant = async () => {
    try {
      setLoading(true)

      const reqBody = {
        message: message,
        rejection_reason_ids: JSON.stringify(reasonIds),
        sent_mail_flag: true,
        scheduled_email_date: moment(new Date())
          .format('YYYY-MM-DD')
          .toLocaleString(),
      }

      let rejectionRoute

      if (rejectStatus === 'reject') {
        rejectionRoute = 'reject'
        setMessage('')
      } else {
        rejectionRoute = 'move'
      }

      await axiosInstance.put(
        `/interviews/${selectedInterview.id}/reject/${rejectionRoute}`,
        reqBody
      )

      const filteredInterviews = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      dispatch(setInterviews(filteredInterviews))

      addToast(
        `${selectedInterview.applicantName} is rejected and moved to Closed stage`,
        {
          appearance: 'info',
          autoDismiss: true,
        }
      )

      setMessage('')
      setLoading(false)

      refreshApplicants()
    } catch (error) {
      setLoading(false)
      addToast('Something went wrong when trying to reject applicant', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const handleScheduledReject = async () => {
    try {
      setScheduledLoading(true)

      const reqBody = {
        message: message,
        rejection_reason_ids: JSON.stringify(reasonIds),
        sent_mail_flag: true,
        scheduled_email_date: moment(addDaysToDate(new Date()))
          .format('YYYY-MM-DD')
          .toLocaleString(),
      }

      let rejectionType = ''
      let successMessage = ''

      if (rejectStatus === 'reject') {
        rejectionType = 'reject'
        successMessage = 'is rejected and moved to Closed stage'
      } else {
        rejectionType = 'move'
        successMessage = 'is moved back to Talent Pool'
      }

      await axiosInstance.put(
        `/interviews/${selectedInterview.id}/reject/${rejectionType}`,
        reqBody
      )

      const filteredInterviews = interviews.filter(
        (interview) => interview.id !== selectedInterview.id
      )

      dispatch(setInterviews(filteredInterviews))

      setMessage('')
      setLoading(false)
      setScheduledLoading(false)
      refreshApplicants()

      addToast(`${selectedInterview.applicantName} ${successMessage}`, {
        appearance: 'info',
        autoDismiss: true,
      })
    } catch (error) {
      setScheduledLoading(false)
      addToast(
        error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }

  const handleTransferApplicant = async (e) => {
    try {
      e.preventDefault()

      if (!jobId) {
        addToast('Please select a tagged vacancy', {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setLoading(true)
        const reqBody = {
          job_id: jobId.toString(),
          role: jobTitle.toString(),
          applicant_id: selectedInterview.applicant_id.toString(),
        }

        const result = await axiosInstance.put(
          `/interviews/${selectedInterview.id}/change-vacancy`,
          reqBody
        )

        const filteredInterviews = interviews.filter(
          (interview) => interview.id !== selectedInterview.id
        )

        dispatch(setInterviews(filteredInterviews))

        refreshApplicants()

        addToast(result?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    } finally {
      setLoading(false)
    }
  }

  const refreshApplicants = () => {
    const appIdx = applicants.findIndex(
      (a) => a.applicant_id === selectedInterview?.applicant_id
    )
    const newApplicantList = applicants
      .filter((a) => a.applicant_id !== selectedInterview?.applicant_id)
      .map((e) => e)

    let updatedModal = []
    if (newApplicantList.length > 0) {
      dispatch(getTalent(newApplicantList[appIdx].applicant_id))
      dispatch(setApplicants(newApplicantList))

      updatedModal = [...modal.filter((item) => item !== 'rejectApplicants')]
    }

    dispatch(setModal(updatedModal))

    dispatch(setUpdatedStage('closed'))
    dispatch(setUpdatedStage(currStage))
    dispatch(setRefreshList(currStage))
  }

  const handleSelectJob = (e) => {
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target.options[selectedIndex]
    const label = selectedOption.textContent
    setJobTitle(label)
    setJobId(e.target.value)
  }

  const handleClose = () => {
    const modals = modal.slice(0, -1)
    dispatch(setModal(modals))
  }

  const handleSelectReasonForRejection = (items) => {
    setReasonForRejection(items)
  }

  useEffect(() => {
    axiosInstance.get('/rejection-reasons').then((res) => {
      const formattedResult = groupOptionsByType(res?.data?.result, {
        typeField: 'rejection_reason_type_name',
        valueField: 'id',
        labelField: 'description',
      })
      setReasonsForRejection(formattedResult)
      setReasonForRejection(formattedResult[0]?.options[0])
    })
  }, [])

  useEffect(() => {
    Promise.all([
      axiosInstance.get('/jobs/dropdown', {
        params: {
          tagged_vacancies: selectedInterview?.applicant_id.toString(),
        },
      }),
      axiosInstance.get('/stages'),
    ])
      .then(([jobsResponse, stagesResponse]) => {
        // Handle jobs response
        const jobResults = jobsResponse?.data?.data?.map((job) => (
          <option key={job?.id} value={job?.id}>
            {job?.title}
          </option>
        ))

        if (jobResults?.length > 0) {
          setJobItems(jobResults)
          setRejectStatus('transfer')
        } else {
          setRejectStatus('reject')
        }

        // Handle stages response
        const stagesResult = stagesResponse?.data?.result
        if (stagesResult?.length) {
          const currStage = stagesResult.filter(
            (val) => val.id === selectedInterview?.stage_id
          )[0].code
          setCurrStage(currStage)
        }
      })
      .catch((error) => {
        // Unified error handling
        if (error) {
          addToast(
            error?.response?.data?.message ||
              error?.response?.message ||
              'Something went wrong',
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        }
      })
  }, [selectedInterview])

  return (
    <MainModal
      buttonText={`${
        rejectStatus === 'transfer' ? 'Transfer Applicant' : 'Reject Applicant'
      }`}
      scheduledButtonText="Reject After 2 Days"
      loading={loading}
      scheduedLoading={scheduledloading}
      onClick={
        rejectStatus === 'transfer'
          ? handleTransferApplicant
          : handleRejectApplicant
      }
      onClickScheduledButton={handleScheduledReject}
      error
      header={
        <ModalHead
          name={selectedInterview.applicantName}
          role={selectedInterview.role}
          onClose={handleClose}
        />
      }
    >
      {jobItems?.length > 0 && (
        <Grid>
          <RadioButton
            name="rejectStatus"
            value={rejectStatus}
            checked={rejectStatus === 'transfer'}
            onChange={() => setRejectStatus('transfer')}
            id="transfer"
          />

          <div>
            <P size="1.25rem" as="label" htmlFor="reject">
              Transfer to Tagged Vacancy
            </P>
            <P as="label" htmlFor="reject">
              This will transfer the applicant to a tagged vacancy and will
              reset the stage to ‘Screening’
            </P>
          </div>
        </Grid>
      )}
      <Grid>
        <RadioButton
          name="rejectStatus"
          value={rejectStatus}
          checked={rejectStatus === 'reject'}
          onChange={() => setRejectStatus('reject')}
          id="reject"
        />

        <div>
          <P size="1.25rem" as="label" htmlFor="reject">
            Reject Applicant
          </P>
          <P as="label" htmlFor="reject">
            This will remove the applicant from its respective list and moved
            back to the talent pool with a ‘declined’ tag
          </P>
        </div>
      </Grid>
      <Grid>
        <RadioButton
          name="rejectStatus"
          checked={rejectStatus === 'move'}
          value={rejectStatus}
          onChange={() => setRejectStatus('move')}
          id="move"
        />

        <div>
          <P
            size="1.25rem"
            as="label"
            style={{ display: 'block' }}
            htmlFor="move"
          >
            Move to Talent Pool
          </P>
          <P as="label" htmlFor="move">
            This will move applicant back to the talent pool with no ‘declined’
            tag
          </P>
        </div>
      </Grid>

      {rejectStatus === 'transfer' && jobItems?.length > 0 && (
        <SingleDropdownSelect
          title="Select Tagged Vacancy"
          onChange={handleSelectJob}
        >
          <option>Choose an option</option>
          {jobItems}
        </SingleDropdownSelect>
      )}

      {/*  */}
      {rejectStatus === 'reject' && (
        <>
          <P>Reason for Rejection</P>
          <GroupedSelect
            isMulti
            data={reasonsForRejection}
            selected={reasonForRejection}
            onChange={handleSelectReasonForRejection}
          />
          <TextAreaContainer>
            <P>Comments</P>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </TextAreaContainer>
        </>
      )}
    </MainModal>
  )
}

export default RejectApplicant

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`
const TextAreaContainer = styled.div`
  margin-top: 0.5rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    margin-top: 0.875rem;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`
