import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux' 
import { Route, Routes } from 'react-router'

import Heading from '../../../components/dashboard/Heading'
import SearchTopFilter from './components/SearchTermTopFilter'
import JobGridLayoutWithFilters from './components/JobGridLayoutWithFilters'
import TotalJobs from './components/TotalJobs'
import Flex from '../../../components/layouts/Flex'
import styled from 'styled-components'
import { BsList } from 'react-icons/bs'
import { H6 } from '../../../style/Typography.style'
import { toggleSideJobFilters } from '../../../store/actions/jobListingActions' 
import Loader from '../../../components/Loader'
import JobListingDetails from './JobListingDetails'
import JobListingEmptyDetails from './JobListingEmptyDetails'
import axiosInstance from '../../../services/config/AxiosIntance'

const JobListingHomeWithFilters = () => {
  const dispatch = useDispatch()
  const { jobFilterState, activeJobFilters } = useSelector((state) => state.jobListing) 
  const [loading, setLoading] = useState(false)
  const [totalJobs, setTotalJobs] = useState(0)
    const [filterCount, setFilterCount] = useState(0)
  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails
  
  useEffect(() => {
    const sum = Object.keys(activeJobFilters).reduce((acc, key) => {
      return acc + activeJobFilters[key]
    }, 0)
    setFilterCount(sum)
  }, [activeJobFilters])

  useEffect(() => {
    setLoading(false)

    const queryParams = {
      status: 'active',
      aggregate: { column: 'id', type: 'COUNT' },
    }

    if (!recruiterAssistant && role === 'recruiter') {
      queryParams.posted_by = userId
    } else if (role === 'poc') {
      queryParams.companyDetails = JSON.stringify(companyDetails)
    }

    axiosInstance
    .get('/jobs', {
      params: queryParams,
    })
    .then((data) => {
      setTotalJobs(data.data.data.jobs[0]['count(`id`)'])
      setLoading(false)
    })
  }, [jobFilterState])

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Heading
        title="Job Listing"
        subTitle={moment().format('MMMM Do, YYYY')}
        link="/"
      />

      <TotalJobs aggregatedValue={totalJobs} />
      <FieldWrappers>
        <SearchTopFilter />
        <Flex
          margin="0 0 1rem 0"
          items="center"
          gap="0.5rem"
          justify="flex-start"
        >
          <StyledFilterIcon
            size={24}
            onClick={() => dispatch(toggleSideJobFilters())}
          />
          <H6 mb="0">Filters</H6>
          <ActiveFilterCount>{filterCount}</ActiveFilterCount>
        </Flex>

      </FieldWrappers>

      <JobGridLayoutWithFilters filterToggle={toggleSideJobFilters}>
        <Routes>
          <Route path="/" element={<JobListingEmptyDetails />} />
          <Route path="/:id" element={<JobListingDetails />} />
        </Routes>
      </JobGridLayoutWithFilters>
    </div>
  )
}

export default JobListingHomeWithFilters

const FieldWrappers = styled.div`
  position: sticky;
  width: 100%;
  top: 5rem;
  background: white;
  z-index: 101;
`

const StyledFilterIcon = styled(BsList)`
  &:hover {
    cursor: pointer;
  }
`

const ActiveFilterCount = styled.span`
  display: flex;
  border-radius: 50%;
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background-color: rgb(24, 119, 242);
  justify-content: center;
  align-items: center;
`