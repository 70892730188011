import React, { useEffect, useState, useMemo } from 'react'

import { useToasts } from 'react-toast-notifications'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { H6, P } from '../../../../style/Typography.style'

import { FilterCheckBox } from '../../../../components/dashboard/FilterInput'
import MainModal from '../../../../components/dashboard/modal/MainModal'
import GroupedSelect from '../../../../components/GroupedSelect'

import axiosInstance from '../../../../services/config/AxiosIntance'

import { setModal } from '../../../../store/actions/listsActions'

import {
  setUpdatedStage,
  setBulkItems,
} from '../../../../store/actions/recruitersActions'

import useMutation from '../../../../hooks/useMutation'

import { groupOptionsByType } from '../../../../utils/utils'

const BulkRejectApplicants = () => {
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { selectedStage, bulkAction } = useSelector(
    (state) => state.requisitions
  )
  const bulkItems = useSelector((state) => state.requisitions.bulkItems)

  const [rejectionMessage, setRejectionMessage] = useState('')
  const [rejectWithoutEmail, setRejectWithoutEmail] = useState(false)
  const [reasonsForRejection, setReasonsForRejection] = useState([])
  const [reasonForRejection, setReasonForRejection] = useState([])

  const { data, loading, handleRequest, success, error } = useMutation()

  const getTotalSelectedItems = () =>
    Object.values(bulkItems).reduce(
      (total, item) => total + item.selectedItems,
      0
    )

  const reasonIds = useMemo(() => {
    if (Array.isArray(reasonForRejection)) {
      return reasonForRejection.map((e) => e.value)
    }

    return [reasonForRejection.value]
  }, [reasonForRejection])

  const handleSubmit = (e, delayedReject = false) => {
    const cleanedBulkItems = Object.entries(bulkItems)
      .filter(([_, value]) => value.selectedItems > 0)
      .reduce((acc, [key, value]) => {
        const { stage, ...rest } = value
        acc[key] = rest
        return acc
      }, {})

    const payload = {
      bulk_items: cleanedBulkItems,
      rejection_message: rejectionMessage,
      delayed_reject: delayedReject,
      without_email: rejectWithoutEmail,
      rejection_reason_ids: JSON.stringify(reasonIds),
    }

    const rejectionType =
      bulkAction === 'bulkDeclineApplicants' ? 'decline' : 'talent-pool'

    handleRequest(
      `/interviews/bulk-reject/${rejectionType}`,
      payload,
      'POST'
    )
  }

  const handleRejectionMessage = (e) => setRejectionMessage(e.target.value)
  const handleRejectWithoutEmail = (e) =>
    setRejectWithoutEmail(!rejectWithoutEmail)

  const handleClose = () => {
    dispatch(setModal(''))
  }

  const handleSelectReasonForRejection = (items) => {
    setReasonForRejection(items)
  }

  useEffect(() => {
    axiosInstance.get('/rejection-reasons').then((res) => {
      const formattedResult = groupOptionsByType(res?.data?.result, {
        typeField: 'rejection_reason_type_name',
        valueField: 'id',
        labelField: 'description',
      })
      setReasonsForRejection(formattedResult)
      setReasonForRejection(formattedResult[0]?.options[0])
    })
  }, [])

  // check if request was successful.
  useEffect(() => {
    if (data) {
      addToast(data.message, {
        appearance: 'success',
        autoDismiss: true,
      })

      dispatch(setUpdatedStage(selectedStage))

      Object.entries(data.resp).forEach(([key]) => {
        dispatch(
          setBulkItems({
            stage: key,
            checkedItems: [],
            uncheckedItems: [],
            selectedItems: 0,
            selectedAll: false,
          })
        )
      })

      dispatch(setModal(''))
    }
  }, [success])

  useEffect(() => {
    if (error) {
      addToast(
        error?.response?.data?.message ||
          error?.response?.message ||
          'Something went wrong',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      )
    }
  }, [error])

  let modalTitle = ''

  switch (bulkAction) {
    case 'bulkDeclineApplicants':
      modalTitle = 'Decline Applicants'
      break
    case 'bulkMoveToTalentPool':
      modalTitle = 'Move Applicants To Talent Pool'
      break
    default:
      modalTitle = ''
      break
  }

  return (
    <>
      <MainModal
        error
        onClose={handleClose}
        title={modalTitle}
        scheduledButtonText="Reject After 2 Days"
        buttonText="Reject Applicant(s)"
        loading={loading}
        onClick={handleSubmit}
        onClickScheduledButton={(e) => handleSubmit(e, true)}
      >
        <H6>Selected Applicants: {getTotalSelectedItems().toString()}</H6>
        <FilterCheckBox
          checked={rejectWithoutEmail}
          size="small"
          label="Reject without sending email"
          onChange={handleRejectWithoutEmail}
        />

        {bulkAction === 'bulkDeclineApplicants' && (
          <>
            <Container>
              <P>
                Reason for Rejection
                <GroupedSelect
                  isMulti
                  data={reasonsForRejection}
                  selected={reasonForRejection}
                  onChange={handleSelectReasonForRejection}
                />
              </P>
            </Container>
            <Container>
              <P>Comments</P>
              <textarea
                onChange={handleRejectionMessage}
                value={rejectionMessage}
              ></textarea>
            </Container>
          </>
        )}
      </MainModal>
    </>
  )
}

const Container = styled.div`
  margin-top: 1rem;

  textarea {
    width: 100%;
    height: 222px;
    border: 1px solid #aeb3c9;
    outline: none;
    border-radius: none;
    padding: 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;

    &:focus,
    &:hover {
      border: 1px solid #1a1a1a;
    }
  }
`

export default BulkRejectApplicants
