import React, { useState, useCallback, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { useToasts } from 'react-toast-notifications'

import MainModal from '../dashboard/modal/MainModal'
import DashboardInput from '../dashboard/DashboardInput'
import PageButtons from '../PageButtons'
import Loader from '../Loader'
import SearchResults from './SearchResults'

import {
  setInterviewStage,
  setFilters,
} from '../../store/actions/recruitersActions'
import { setModal } from '../../store/actions/listsActions'

import axiosInstance from '../../services/config/AxiosIntance'

import { debounce } from '../../utils/utils'

const SmartSearch = ({ timer = 3000 }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const [interviews, setInterviews] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedAction = useCallback(
    debounce((text) => {
      setPageNumber(1)
      setSearchTerm(text)
    }, timer),
    [timer]
  )

  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const handleTextChanged = (e) => {
    setLoading(true)
    debouncedAction(e.target.value)
  }

  const handlePageChanged = (page) => {
    setLoading(true)
    setPageNumber(page)
  }

  const handleSearchResultClicked = (params) => {
    dispatch(setInterviewStage(params.stages))
    dispatch(
      setFilters({
        smartSearchId: params.id,
      })
    )
    dispatch(setModal(''))
  }

  useEffect(() => {
    axiosInstance
      .get(
        `/interviews?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=5`
      )
      .then((res) => {
        setInterviews(res?.data?.resp?.interviews)
        setPageCount(res?.data?.resp?.pagination.pageCount)
      })
      .catch((err) =>
        addToast.error(err?.response?.data || 'Something went wrong', {
          appearance: 'error',
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }, [searchTerm, pageNumber])

  return (
    <MainModal
      header={
        <DashboardInput
          handleChange={handleTextChanged}
          placeholder="Enter text..."
        />
      }
      buttonText="Close"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <SearchResults
            data={interviews}
            onSearchResultClicked={handleSearchResultClicked}
          />
          {interviews.length > 0 && (
            <PageButtons
              totalPages={pageCount}
              currentPage={pageNumber}
              onPageClick={handlePageChanged}
            />
          )}
        </>
      )}
    </MainModal>
  )
}

export default SmartSearch
