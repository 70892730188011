import { jobs } from '../types/jobListing'
import axiosInstance from '../../services/config/AxiosIntance'
import { countTruthyPropertiesOfObject } from '../../utils/utils'

export const getJobLists = (joblists, pagination) => {
  return {
    type: jobs.GET_JOB_LISTINGS,
    payload: { jobs: joblists, pagination: pagination },
  }
}

export const isLoading = (loading) => {
  return {
    type: jobs.IS_LOADING,
    payload: loading,
  }
}

export const setModal = (modal) => {
  return {
    type: jobs.SET_MODAL,
    payload: modal,
  }
}

export const setNotification = (type, message) => (dispatch) => {
  dispatch({
    type: jobs.SET_NOTIFICATION,
    payload: { type: type, message: message },
  })
}

export const closeJob = (id) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    const result = await axiosInstance.post(`/job/close`, { job_id: id })
    const successMessage = result?.data?.message

    dispatch(setNotification('success', successMessage))
    dispatch({
      type: jobs.SELECT_JOB,
      payload: result?.data?.resp,
    })
  } catch (error) {
    const errorMessage = error?.data?.message || 'Something went wrong.'
    dispatch(setNotification('error', errorMessage))
  } finally {
    dispatch(setModal(''))
    dispatch(isLoading(false))
  }
}

export const selectJob = (job) => (dispatch) => {
  dispatch({
    type: jobs.SELECT_JOB,
    payload: job,
  })
}

export const setJobUpdated = (jobUpdated) => {
  return {
    type: jobs.SET_JOB_UPDATED,
    payload: jobUpdated,
  }
}

export const filterJobList = (params) => (dispatch, getState) => {
  dispatch(isLoading(true))
  axiosInstance
    .get('/jobs', {
      params: {
        ...params,
      },
    })
    .then((res) => {
      dispatch(isLoading(false))
      dispatch(getJobLists(res.data.data.jobs, res.data.data.pagination))
      dispatch(setJobUpdated(false))
    })
    .catch((err) => {
      console.log(
        'error',
        err.response.status,
        err.message,
        err.response.headers,
        err.response.data
      )
    })
}

export const toggleSideJobFilters = (jobFilterState) => {
  return {
    type: jobs.SET_TOGGLE_SIDE_JOBFILTERS,
    payload: jobFilterState,
  }
}

export const setJobTitle = (jobTitle) => {
  return {
    type: jobs.SET_JOBTITLE,
    payload: jobTitle,
  }
}

export const setJobRole = (jobRole) => {
  return {
    type: jobs.SET_JOBROLE,
    payload: jobRole,
  }
}

export const setJobTitleKeyword = (jobTitlekeyword) => {
  return {
    type: jobs.SET_JOBTITLE_KEYWORD,
    payload: jobTitlekeyword,
  }
}

export const setLocation = (location) => {
  return {
    type: jobs.SET_LOCATION,
    payload: location,
  }
}

export const setIndustry = (industry) => {
  return {
    type: jobs.SET_INDUSTRY,
    payload: industry,
  }
}

export const setExperienceLevel = (experienceLevel) => {
  return {
    type: jobs.SET_EXPERIENCE_LEVEL,
    payload: experienceLevel,
  }
}

export const setJobType = (jobType) => {
  return {
    type: jobs.SET_JOBTYPE,
    payload: jobType,
  }
}

export const setSalaryRange = (salaryRange) => {
  return {
    type: jobs.SET_SALARY_RANGE,
    payload: salaryRange,
  }
}

export const setMinimumSalary = (minSalaryRange) => {
  return {
    type: jobs.SET_MINSALARY_RANGE,
    payload: minSalaryRange,
  }
}

export const setMaximumSalary = (maxSalaryRange) => {
  return {
    type: jobs.SET_MAXSALARY_RANGE,
    payload: maxSalaryRange,
  }
}

export const setSearchTermKeyword = (searchTermKeyword) => {
  return {
    type: jobs.SET_SEARCHTERM_KEYWORD,
    payload: searchTermKeyword,
  }
}

export const setRequiredSkills = (requiredSkills) => {
  return {
    type: jobs.SET_REQUIRED_SKILLS,
    payload: requiredSkills,
  }
}

export const setRequiredSkillsSoftware = (requiredSkillsSoftware) => {
  return {
    type: jobs.SET_REQUIRED_SKILLS_SOFTWARE,
    payload: requiredSkillsSoftware,
  }
}

export const setCertifications = (certifications) => {
  return {
    type: jobs.SET_CERTIFICATIONS,
    payload: certifications,
  }
}

export const setPostedDate = (postedDate) => {
  return {
    type: jobs.SET_POSTED_DATE,
    payload: postedDate,
  }
}

export const setMinDateRange = (minDateRange) => {
  return {
    type: jobs.SET_MINDATE_RANGE,
    payload: minDateRange,
  }
}

export const setMaxDateRange = (maxDateRange) => {
  return {
    type: jobs.SET_MAXDATE_RANGE,
    payload: maxDateRange,
  }
}

export const setBenefits = (benefits) => {
  return {
    type: jobs.SET_BENEFITS,
    payload: benefits,
  }
}

export const setEducationReq = (educationReq) => {
  return {
    type: jobs.SET_EDUCATION_REQ,
    payload: educationReq,
  }
}

export const calculateActiveJobFilters = () => (dispatch, getState) => {
  const jobListState = getState().jobListing

  const jobFilterTerm = ['jobTitle', 'jobTitlekeyword', 'jobRole']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'jobFilterItems',
      amount: countTruthyPropertiesOfObject(jobListState, jobFilterTerm),
    },
  })

  const jobTypeFilterTerm = ['jobType']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'jobTypeItems',
      amount: countTruthyPropertiesOfObject(jobListState, jobTypeFilterTerm),
    },
  })

  const locationFilterTerm = ['location']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'locationItems',
      amount: countTruthyPropertiesOfObject(jobListState, locationFilterTerm),
    },
  })

  const industryFilterTerm = ['industry']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'industryItems',
      amount: countTruthyPropertiesOfObject(jobListState, industryFilterTerm),
    },
  })

  const experienceLevelFilterTerm = ['experienceLevel']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'experienceLevelItems',
      amount: countTruthyPropertiesOfObject(
        jobListState,
        experienceLevelFilterTerm
      ),
    },
  })

  const salaryRangeFilterTerm = [
    'salaryRange',
    'minSalaryRange',
    'maxSalaryRange',
  ]
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'salaryRangeItems',
      amount: countTruthyPropertiesOfObject(
        jobListState,
        salaryRangeFilterTerm
      ),
    },
  })

  const reqSkillsFilterTerm = [
    'requiredSkills',
    'requiredSkillsSoftware',
    'certifications',
  ]
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'requiredSkillsItems',
      amount: countTruthyPropertiesOfObject(jobListState, reqSkillsFilterTerm),
    },
  })

  const postedDateFilterTerm = ['postedDate', 'minDateRange', 'maxDateRange']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'postedDateItems',
      amount: countTruthyPropertiesOfObject(jobListState, postedDateFilterTerm),
    },
  })

  const benefitsFilterTerm = ['benefits']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'benefitsItems',
      amount: countTruthyPropertiesOfObject(jobListState, benefitsFilterTerm),
    },
  })

  const educationReqFilterTerm = ['educationReq']
  dispatch({
    type: jobs.INCREASE_ACTIVEJOB_FILTER,
    payload: {
      prop: 'educationalReqItems',
      amount: countTruthyPropertiesOfObject(
        jobListState,
        educationReqFilterTerm
      ),
    },
  })
}
