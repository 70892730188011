export const jobs = {
  GET_JOB_LISTINGS: 'GET_JOB_LISTINGS',
  IS_LOADING: 'IS_LOADING',
  SET_MODAL: 'SET_MODAL',
  CLOSE_JOB: 'CLOSE_JOB',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SELECT_JOB: 'SELECT_JOB',
  SET_JOB_CHANGED: 'SET_JOB_CHANGED',
  SET_JOB_UPDATED: 'SET_JOB_UPDATED',

  SET_TOGGLE_SIDE_JOBFILTERS: 'SET_TOGGLE_SIDE_JOBFILTERS',
  SET_ACTIVE_JOBFILTERS: 'SET_ACTIVE_JOBFILTERS',
  SET_JOBTITLE: 'SET_JOBTITLE',
  SET_JOBTITLE_KEYWORD: 'SET_JOBTITLE_KEYWORD',
  SET_JOBROLE: 'SET_JOBROLE',
  SET_LOCATION: 'SET_LOCATION',
  SET_INDUSTRY: 'SET_INDUSTRY',
  SET_EXPERIENCE_LEVEL: 'SET_EXPERIENCE_LEVEL',
  SET_JOBTYPE: 'SET_JOBTYPE',
  SET_SALARY_RANGE: 'SET_SALARY_RANGE',
  SET_MINSALARY_RANGE: 'SET_MINSALARY_RANGE',
  SET_MAXSALARY_RANGE: 'SET_MAXSALARY_RANGE',
  SET_SEARCHTERM_KEYWORD: 'SET_SEARCHTERM_KEYWORD',
  SET_REQUIRED_SKILLS: 'SET_REQUIRED_SKILLS',
  SET_REQUIRED_SKILLS_SOFTWARE: 'SET_REQUIRED_SKILLS_SOFTWARE',
  SET_CERTIFICATIONS: 'SET_CERTIFICATIONS',
  SET_POSTED_DATE: 'SET_POSTED_DATE',
  SET_MINDATE_RANGE: 'SET_MINDATE_RANGE',
  SET_MAXDATE_RANGE: 'SET_MAXDATE_RANGE',
  SET_BENEFITS: 'SET_BENEFITS',
  SET_EDUCATION_REQ: 'SET_EDUCATION_REQ',
  INCREASE_ACTIVEJOB_FILTER: 'INCREASE_ACTIVEJOB_FILTER',
}
