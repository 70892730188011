import { useSelector, useDispatch } from 'react-redux'

import { setBulkItems } from '../../../../store/actions/recruitersActions'

const useBulkActions = () => {
  const dispatch = useDispatch()

  const selectedStage = useSelector((state) => state.requisitions.selectedStage)

  const selectedAll = useSelector(
    (state) => state.requisitions.bulkItems[selectedStage]?.selectedAll
  )
  const checkedItems = useSelector(
    (state) => state.requisitions.bulkItems[selectedStage]?.checkedItems
  )
  const uncheckedItems = useSelector(
    (state) => state.requisitions.bulkItems[selectedStage]?.uncheckedItems
  )
  const currentSelectedItems = useSelector(
    (state) => state.requisitions.bulkItems[selectedStage]?.selectedItems
  )

  const uncheckedItemList = uncheckedItems?.map((e) => e)
  const checkedItemList = checkedItems?.map((e) => e)

  const getCheckedResult = (value) =>
    selectedAll
      ? !uncheckedItemList?.includes(value)
      : checkedItemList?.includes(value)

  const handleCheckColumn = (e, totalRowCount) => {
    const payload = {
      selectedAll: e.target.checked,
    }

    if (payload.selectedAll) {
      payload.uncheckedItems = []
      payload.selectedItems = totalRowCount
    } else {
      payload.checkedItems = []
      payload.selectedItems = 0
    }

    dispatch(setBulkItems(payload))
  }

  const handleCheckRow = (e, value) => {
    e.stopPropagation()
    let updatedCheckedItems = []
    let updatedUncheckedItems = []
    let updatedSelectedItems = 0

    if (selectedAll) {
      if (uncheckedItemList.includes(value)) {
        updatedUncheckedItems = uncheckedItemList.filter((e) => e !== value)
        updatedSelectedItems = currentSelectedItems + 1
      } else {
        updatedUncheckedItems = [...uncheckedItems, value]
        updatedSelectedItems = currentSelectedItems - 1
      }
    } else {
      if (checkedItemList.includes(value)) {
        updatedCheckedItems = checkedItemList.filter((e) => e !== value)
      } else {
        updatedCheckedItems = [...checkedItems, value]
      }
      updatedSelectedItems = updatedCheckedItems.length
    }

    const payload = {
      checkedItems: updatedCheckedItems,
      uncheckedItems: updatedUncheckedItems,
      selectedItems: updatedSelectedItems,
    }

    dispatch(setBulkItems(payload))
  }

  return {
    getCheckedResult,
    handleCheckColumn,
    handleCheckRow,
    selectedAll,
    checkedItems,
  }
}

export default useBulkActions
