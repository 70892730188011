import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setIndustry } from '../../../../../../src/store/actions/jobListingActions'
import { fetchProfessionalHeadlines } from '../../../../../store/actions/talentActions' 

const Industry = () => {
  const dispatch = useDispatch()
  const { industry } = useSelector((state) => state.jobListing)  
  const { professionalHeadlines} = useSelector((state) => state.talents)

  // const options = [
  //   "Construction & Real Estate",
  //   "Education & E-Learning",
  //   "Energy & Utilities",
  //   "Entertainment & Media",
  //   "Finance & Banking",
  //   "Healthcare & Medical",
  //   "Hospitality & Tourism",
  //   "Legal & Compliance",
  //   "Logistics & Transportation",
  //   "Manufacturing & Engineering",
  //   "Marketing & Advertising",
  //   "Retail & E-Commerce",
  //   "Technology & IT",
  // ]
    useEffect(() => {  
      dispatch(fetchProfessionalHeadlines())
    }, [dispatch])
  
  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="industry"
            title="Industry"
            value={industry}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setIndustry(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {/* {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))} */}
          { professionalHeadlines.map((item) => (
            <option key={item.id} value={item.professional_headline.toString()}>
              {item.professional_headline}
            </option>
          ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default Industry

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`