import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  align-items: ${({ items }) => items || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => gap || '0'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  min-height: ${({ minh }) => minh || '0'};
  max-width: ${({ maxw }) => maxw || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  position: ${({ position }) => position || 'relative'};
  border: ${({ border }) => border || 'none'};
  border-top: ${({ bT, border }) => bT || border};
  border-bottom: ${({ bB, border }) => bB || border};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  ${({ top }) => top && `top: ${top}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
  background: ${({ bg }) => bg || 'none'};
  overflow: ${({ overflow }) => overflow || 'visible'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'clip'};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  overflow-y: ${({ overflowY }) => overflowY || 'visible'};

  @media (max-width: 768px) {
    gap: ${({ gap, gapMobile }) => gapMobile || gap};
    flex-direction: ${({ mobileDirection, direction }) =>
      mobileDirection || direction || 'row'};
    margin: ${({ margin, marginMobile }) => marginMobile || margin};
    max-width: ${({ maxw, maxwMobile }) => maxwMobile || maxw};
  }
`

export default Flex
