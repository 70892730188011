import React from 'react'

import styled from 'styled-components'

import Flex from '../layouts/Flex'

import { P } from '../../style/Typography.style'

const SearchResult = ({
  id,
  name,
  email,
  role,
  stage,
  recruiter,
  channel,
  client,
  onSearchResultClicked,
}) => {
  return (
    <StyledSearchResult onClick={onSearchResultClicked}>
      <Flex gap="1rem" justify="space-between">
        <Flex
          direction="column"
          width="25%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Applicant</P>
          <P weight="500">{name}</P>
        </Flex>
        <Flex
          direction="column"
          width="25%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Email</P>
          <P weight="500">{email}</P>
        </Flex>
        <Flex
          direction="column"
          width="25%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Role</P>
          <P weight="500">{role}</P>
        </Flex>
        <Flex
          direction="column"
          width="25%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Stage</P>
          <P weight="500">{stage}</P>
        </Flex>
      </Flex>
      <Flex overflow="hidden">
        <Flex
          direction="column"
          width="25%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Recruiter</P>
          <P weight="500">{recruiter || 'Not specified'}</P>
        </Flex>
        <Flex
          direction="column"
          width="25%"
          argin="0 0 0 1rem"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Channel</P>
          <P weight="500">{channel || 'Not specified'}</P>
        </Flex>
        <Flex
          direction="column"
          width="30%"
          margin="0 0 0 0.5rem"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <P>Client</P>
          <P weight="500">{client || 'Not specified'}</P>
        </Flex>
      </Flex>
    </StyledSearchResult>
  )
}

const StyledSearchResult = styled.div`
  padding: 0.5rem;
  margin-top: 5px;
  cursor: pointer;
  border: 1px solid #aeb3c9;
  height: 100px;
`

export default SearchResult
