import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setJobType } from '../../../../../store/actions/jobListingActions'

const JobType = () => {
  const dispatch = useDispatch()
  const { jobType } = useSelector((state) => state.jobListing)  

  const options = [
    "Full-time",
    "Part-time",
    "Contract",
    "Freelance",
    "Temporary"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="jobType"
            title="Job Type"
            value={jobType}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setJobType(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default JobType

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`