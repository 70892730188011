import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setRequiredSkills } from '../../../../../store/actions/jobListingActions'

const RequiredSkills = () => {
  const dispatch = useDispatch()
  const { requiredSkills } = useSelector((state) => state.jobListing)  

  const options = [
    "3D modeling",
    "Ad buying",
    "Admin operations",
    "Agile methodologies",
    "Agile/Scrum",
    "analytics",
    "analytics",
    "animation",
    "AP/AR management",
    "APIs",
    "ATS",
    "auditing",
    "Automated/manual testing",
    "automation",
    "bookkeeping",
    "branding",
    "business intelligence tools",
    "Call handling",
    "campaign execution",
    "campaign optimization",
    "client engagement",
    "client management",
    "Client relations",
    "Client relations",
    "cloud computing",
    "Cold calling",
    "collections management",
    "color correction",
    "communication",
    "Compliance",
    "confidentiality",
    "construction project support",
    "content creation",
    "Content planning",
    "content strategy",
    "copywriting",
    "Cost estimation",
    "credit analysis",
    "Credit risk analysis",
    "cross-functional collaboration",
    "customer engagement",
    "customer service",
    "customer support",
    "Data analysis",
    "data analysis",
    "data entry",
    "Debt collection",
    "digital marketing",
    "digital marketing",
    "document drafting",
    "documentation",
    "documentation",
    "email management",
    "Employee training",
    "engagement",
    "Equipment maintenance",
    "escalation management",
    "Excel modeling",
    "financial compliance",
    "financial modeling",
    "financial records management",
    "Financial statements",
    "Graphic design",
    "High-level administrative support",
    "HR policies",
    "HR policies",
    "HR strategy",
    "HR tools",
    "HRIS",
    "hydraulic fracturing",
    "interview coordination",
    "interview techniques",
    "Inventory management",
    "Invoice processing",
    "IT solutions",
    "IT troubleshooting",
    "Keyword research",
    "KPI monitoring",
    "lead generation",
    "leadership",
    "Leadership",
    "Legal document handling",
    "link building",
    "loan underwriting",
    "logistics support",
    "Market research",
    "motion graphics",
    "negotiation",
    "negotiation",
    "networking",
    "New hire training",
    "Office management",
    "operational efficiency",
    "order processing",
    "organization",
    "Paralegal knowledge",
    "payroll",
    "Payroll processing",
    "petroleum engineering",
    "problem-solving",
    "process improvement",
    "Process optimization",
    "product lifecycle",
    "Product listing",
    "project management",
    "Property management",
    "Quality control",
    "Real estate processes",
    "reconciliation",
    "Relationship management",
    "reporting",
    "research",
    "Resume screening",
    "Risk assessment",
    "ROI analysis",
    "SaaS platforms",
    "SaaS support",
    "safety compliance",
    "Sales",
    "Sales forecasting",
    "sales scripts",
    "Scheduling",
    "Scheduling",
    "SEO writing",
    "service delivery",
    "Social media",
    "sourcing strategies",
    "stakeholder management",
    "Strategic financial management",
    "Supply chain management",
    "system design",
    "talent development",
    "task tracking",
    "tax compliance",
    "testing tools",
    "texturing",
    "time management",
    "troubleshooting",
    "typography",
    "UX/UI principles",
    "valuation",
    "vendor coordination",
    "vendor management",
    "Video editing",
    "Well completion design"

  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="requiredSkills"
            title="Required Skills"
            value={requiredSkills}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setRequiredSkills(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default RequiredSkills

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`