import React, { useEffect, useState  } from 'react' // 
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../../src/components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'
import axiosInstance from '../../../../../services/config/AxiosIntance'
import { setJobTitle } from '../../../../../../src/store/actions/jobListingActions'

const JobTitle = () => {
  const dispatch = useDispatch()
  const { jobTitle } = useSelector((state) => state.jobListing)  
  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant
  const companyDetails = role === 'poc' && JSON.parse(currentUser)?.companyDetails
  const [options, setJobTitleList] = useState([])

  function getJobListings(params) {
    axiosInstance
      .get('/jobs/dropdown', {
        params: {
          ...params,
        },
      })
      .then((res) => {
        const titles = res.data.data.map(job => job.title);
        setJobTitleList(titles)
      })
      .catch((err) => {
        console.log(
          'error',
          err.response.status,
          err.message,
          err.response.headers,
          err.response.data
        )
      })
  }

  useEffect(() => {  
    const params = {
      page: 1,
    }
    if (role === 'recruiter' && !recruiterAssistant) {
      params.posted_by = userId
      params.status = 'active'
    } else if (role === 'poc') {
      params.companyDetails = JSON.stringify(companyDetails)
    }

    getJobListings(params)
  }, [])


  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="jobTitle"
            title="Job Title"
            value={jobTitle}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setJobTitle(selectedValue === "Choose Option" ? null : selectedValue));
            }} 
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default JobTitle

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`