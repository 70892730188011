/* eslint-disable camelcase */
import React, { useEffect } from 'react' 
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'

import Loader from '../../../../components/Loader'
import PageButtons from '../../../../components/PageButtons'
// import axiosInstance from '../../../../services/config/AxiosIntance'
import JobList from './JobList'

import {
  setSearchTermKeyword,
  filterJobList
} from '../../../../store/actions/jobListingActions'

const JobListingWrapper = () => {
  const dispatch = useDispatch()
  const { searchTermKeyword, jobListings, loading, pagination, selectedJob, jobUpdated,
          jobTitle, jobTitlekeyword, jobRole, location, industry, experienceLevel, jobType, 
          salaryRange, minSalaryRange, maxSalaryRange,
          requiredSkills, requiredSkillsSoftware, certifications, 
          postedDate, minDateRange, maxDateRange,
          benefits, educationReq
        } = useSelector((state) => state.jobListing)

  const currentUser = localStorage.getItem('user')
  const role = JSON.parse(currentUser)?.user_type
  const userId = JSON.parse(currentUser)?.id
  const recruiterAssistant = !!JSON.parse(currentUser)?.recruiter_assistant

  const companyDetails =
    role === 'poc' && JSON.parse(currentUser)?.companyDetails


  useEffect(() => {
    const params = {
      page: 1,
    }

    if (role === 'recruiter' && !recruiterAssistant) {
      params.posted_by = userId
      params.status = 'active'
    } else if (role === 'poc') {
      params.companyDetails = JSON.stringify(companyDetails)
    }

    dispatch(filterJobList(params))
  }, [selectedJob])


  function handleFetch(page) {
    let salaryRangeValue = null;
    let postedby = null;
    let company = null;

    if (role === 'recruiter' && !recruiterAssistant) {
      postedby = userId
    } else if (role === 'poc') {
      company = JSON.stringify(companyDetails)
    }

    if(salaryRange !== null){
      salaryRangeValue = minSalaryRange + ',' + maxSalaryRange //*
    }

    const jobTitleValues = (jobTitle || jobTitlekeyword || jobRole) 
      ? { job_Title: jobTitle, job_Titlekeyword: jobTitlekeyword, job_Role: jobRole }
      : null;
    
    const reqSkillsAndCerts = (requiredSkills || requiredSkillsSoftware || certifications) 
      ? { reqSkill: requiredSkills, reqSoftware: requiredSkillsSoftware, certification: certifications }
      : null;

    const params = {
      searchJobBy: searchTermKeyword && searchTermKeyword !== null ? searchTermKeyword : undefined,
      salary_basis: salaryRangeValue && salaryRangeValue !== null ? salaryRangeValue : undefined,
      date_posted: postedDate && postedDate !== null ? postedDate : undefined,            
      minDateRange: postedDate && postedDate !== null ? minDateRange : undefined,           
      maxDateRange: postedDate && postedDate !== null ? maxDateRange : undefined,          
      country: location && location !== null ? location : undefined,
      job_type: jobType && jobType !== null ? jobType : undefined,                
      job_terms: jobTitleValues && jobTitleValues !== null ? jobTitleValues : undefined,        
      industry: industry && industry !== null ? industry : undefined,
      skills_terms: reqSkillsAndCerts && reqSkillsAndCerts !== null ? reqSkillsAndCerts : undefined,  
      benefits: benefits && benefits !== null ? benefits : undefined,
      educationReq: educationReq && educationReq !== null ? educationReq : undefined,
      experienceLevel: experienceLevel && experienceLevel !== null ? experienceLevel : undefined,
      posted_by: postedby && postedby !== null ? postedby : undefined,
      companyDetails: company && company !== null ? company : undefined,
      page: page,
    }
    
    dispatch(setSearchTermKeyword(searchTermKeyword))
    dispatch(filterJobList(params))
  }

  useEffect(() => {
    if (jobUpdated) {
      let salaryRangeValue = null;
      let postedby = null;
      let company = null;
      let stat = null;
  
      if (role === 'recruiter' && !recruiterAssistant) {
        postedby = userId
      } else if (role === 'poc') {
        company = JSON.stringify(companyDetails)
      }
  
      if (role === 'recruiter' && !recruiterAssistant) {
        postedby = userId
        stat = 'active'
      } else if (role === 'poc') {
        company = JSON.stringify(companyDetails)
      }


      if(salaryRange !== null){
        salaryRangeValue = minSalaryRange + ',' + maxSalaryRange //*
      }
  
      const jobTitleValues = (jobTitle || jobTitlekeyword || jobRole) 
        ? { job_Title: jobTitle, job_Titlekeyword: jobTitlekeyword, job_Role: jobRole }
        : null;
      
      const reqSkillsAndCerts = (requiredSkills || requiredSkillsSoftware || certifications) 
        ? { reqSkill: requiredSkills, reqSoftware: requiredSkillsSoftware, certification: certifications }
        : null;
  
      const params = {
        searchJobBy: searchTermKeyword && searchTermKeyword !== null ? searchTermKeyword : undefined,
        salary_basis: salaryRangeValue && salaryRangeValue !== null ? salaryRangeValue : undefined,
        date_posted: postedDate && postedDate !== null ? postedDate : undefined,            
        minDateRange: postedDate && postedDate !== null ? minDateRange : undefined,           
        maxDateRange: postedDate && postedDate !== null ? maxDateRange : undefined,          
        country: location && location !== null ? location : undefined,
        job_type: jobType && jobType !== null ? jobType : undefined,                
        job_terms: jobTitleValues && jobTitleValues !== null ? jobTitleValues : undefined,        
        industry: industry && industry !== null ? industry : undefined,
        skills_terms: reqSkillsAndCerts && reqSkillsAndCerts !== null ? reqSkillsAndCerts : undefined,  
        benefits: benefits && benefits !== null ? benefits : undefined,
        educationReq: educationReq && educationReq !== null ? educationReq : undefined,
        experienceLevel: experienceLevel && experienceLevel !== null ? experienceLevel : undefined,
        posted_by: postedby && postedby !== null ? postedby : undefined,
        companyDetails: company && company !== null ? company : undefined,
        status: stat && stat !== null ? stat : undefined,
        page: pagination?.page || 1,
      }
      
      dispatch(setSearchTermKeyword(searchTermKeyword))      
      dispatch(filterJobList(params))
    }
  }, [jobUpdated])

  return (
    <Wrapper>
      <StyledJobListingWrapper>
        {!loading ? (
          <>
            {jobListings.length > 0 ? (
              jobListings?.map(
                ({
                  title,
                  status,
                  min_salary,
                  max_salary,
                  created_at,
                  id,
                  active_vacancy,
                  vacancy_for_pooling,
                  interviews,
                  priority,
                  currencies,
                }) => (
                  <JobList
                    key={id}
                    id={id}
                    title={title}
                    status={status}
                    min_salary={min_salary}
                    max_salary={max_salary}
                    created_at={created_at}
                    interviews={interviews}
                    active_vacancy={active_vacancy}
                    vacancy_for_pooling={vacancy_for_pooling}
                    priority={priority}
                    currencies={currencies}
                  />
                )
              )
            ) : (
              <EmptyList>No Job Found</EmptyList>
            )}
          </>
        ) : (
          <Loader />
        )}
      </StyledJobListingWrapper>

      <Pagination>
        <PageButtons
          totalPages={pagination?.pageCount}
          currentPage={pagination?.page}
          onPageClick={handleFetch}
          maxWidth={10}
        />

        <select value={pagination?.pageSize}>
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              View {pageSize}
            </option>
          ))}
        </select>
        <span>{`${pagination.page} - ${pagination?.pageSize} of ${pagination?.rowCount}`}</span>
      </Pagination>
    </Wrapper>
  )
}

export default JobListingWrapper

const Wrapper = styled.div`
  width: 100%;
  height: 70%;
`

const EmptyList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
  color: #676e92;
`

const StyledJobListingWrapper = styled.div`
  height: calc(100vh - 20rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
`

const Pagination = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  bottom: 15px;

  select {
    height: 2rem;
    background: #ffffff;
    border: 0.5px solid #aeb3c9;
    margin: 0 0.75rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    outline: none;
  }

  span {
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
  }
`
