import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  // /components/dashboard/TalentPool/DropdownSelect
import styled from 'styled-components'

import { setBenefits } from '../../../../../store/actions/jobListingActions'

const Benefits = () => {
  const dispatch = useDispatch()
  const { benefits } = useSelector((state) => state.jobListing)  

  const options = [
    "Health insurance",
    "Paid time off",
    "Retirement plans",
    "Professional development",
    "Remote work flexibility"
  ]

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="benefits"
            title="Benefits"
            value={benefits}
            onChange={(e) => {
              const selectedValue = e.target.value;
              dispatch(setBenefits(selectedValue === "Choose Option" ? null : selectedValue));
            }}
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default Benefits

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`