import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleDropdownSelect } from '../../../../../components/dashboard/TalentPool/DropdownSelect'  
import styled from 'styled-components'

import { setSalaryRange, setMinimumSalary, setMaximumSalary } from '../../../../../store/actions/jobListingActions'

const SalaryRange = () => {
  const dispatch = useDispatch()
  const { salaryRange } = useSelector((state) => state.jobListing)  

  const options = [
    "below 20,000",
    "20,000-29,999",
    "30,000-39,999",
    "40,000-49,999",
    "50,000-59,999",
    "60,000-69,999",
    "70,000-79,999",
    "80,000-89,999",
    "90,000-99,999",
    "100,000 above",
  ]

  const salaryMapping  = {
    "below 20,000": [0, 19999],
    "20,000-29,999": [20000, 29999],
    "30,000-39,999": [30000, 39999],
    "40,000-49,999": [40000, 49999],
    "50,000-59,999": [50000, 59999],
    "60,000-69,999": [60000, 69999],
    "70,000-79,999": [70000, 79999],
    "80,000-89,999": [80000, 89999],
    "90,000-99,999": [90000, 99999],
    "100,000 above": [100000, 999999],
  }

  const handleSalaryRange = (e) => {
    const selectedValue = e.target.value;
    dispatch(setSalaryRange(selectedValue === "Choose Option" ? null : selectedValue));
  
    if (selectedValue !== "Choose Option") {
      const [minSalary, maxSalary] = salaryMapping[selectedValue] || [];
      if (minSalary !== undefined && maxSalary !== undefined) {
        dispatch(setMinimumSalary(minSalary));
        dispatch(setMaximumSalary(maxSalary));
      }
    }
  };

  return (
    <StyledJobListFilter>
        <SingleDropdownSelect
            name="salaryRange"
            title="Salary Range"
            value={salaryRange}
            onChange={(e) => handleSalaryRange(e)}
            maxw="350px"
        >
            <option>Choose Option</option>
            {options.map((option, index) => (
            <option key={index} value={option}>
                {option}
            </option>
            ))}
        </SingleDropdownSelect>
    </StyledJobListFilter>
  )
}

export default SalaryRange

const StyledJobListFilter = styled.div`
    float: left;
    padding-right: 20px;
`